import styled from "styled-components";

const PanelActionsContainer = styled.div`
  background: black;
  width: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: 20px 40px;
  justify-content: end;
  margin: 0 auto;
  color: white;
  gap: 15px;
  box-shadow: 0px 0px 5px 2px lightgray;
  @media screen and (max-width: 640px) {
    // display: none;
  }
`;

const Button = styled.div`
  font-weight: bold;
  border: 3px solid white;
  border-radius: 3px;
  padding: 8px 20px;
  background: white;
  color: black;
  &:hover {
    cursor: pointer;
    background: #282d38;
    color: white;
    border: 3px solid #282d38;
  }
  &.outlined {
    background: black;
    border: 3px solid white;
    color: white;
    &:hover {
      background: white;
      color: black;
    }
  }
`;
function PanelActions() {
  // const handleClick = () => {};
  // const handleSupportClick = () => {
  // };

  return (
    <PanelActionsContainer>
      <Button className="outlined">Get Support</Button>
      <Button>Action</Button>
    </PanelActionsContainer>
  );
}

export default PanelActions;
