import styled from "styled-components";
import Panel from "views/panels/panel";

const PanelContainer = styled(Panel)`
  display: flex;
  flex: 1.8;
  flex-direction: column;
  height: 100%;
`;

function Panel2(props) {
  const { panel2 } = props;

  return <PanelContainer panel2={panel2} className="panel2" title={panel2} />;
}

export default Panel2;
