import styled from "styled-components";
import ToolHeading from "views/tools/ToolHeading";

import {
  ListItemIcon as ListItemIconMui,
  ListItemText as ListItemTextMui,
  MenuItem as MenuItemMui,
  MenuList,
  SwipeableDrawer as SwipeableDrawerMui,
} from "@mui/material";

const ToolDropdownContainer = styled.div`
  display: flex;
  align-items: center;
`;

const MenuItemsContainer = styled.div`
  background: black;
  color: white;
  padding-top: 85.49px;
  padding-bottom: 65px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 100%;
  width: 100%;
`;

const MenuItemContainer = styled(MenuList)`
  background: black;
`;

const MenuItem = styled(MenuItemMui)`
  padding: 10px 20px !important;
`;

const ListItemIcon = styled(ListItemIconMui)`
  min-width: 44px !important;
`;

const ListItemText = styled(ListItemTextMui)`
  .MuiTypography-root {
    font-size: 16px;
    font-family: pt sans;
    line-height: 20px;
    font-weight: 700;
  }
`;

const SelectBox = styled.div`
  width: 200px;
  height: 40px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 10px;
  background: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-family: "Roboto";
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const SwipeableDrawer = styled(SwipeableDrawerMui)`
  .MuiDrawer-paper {
    height: 70%;
    background-color: transparent;
    @media screen and (min-width: 640px) {
      display: none;
    }
  }
  .MuiBackdrop-root {
    opacity: 0 !important;
    @media screen and (min-width: 640px) {
      display: none;
    }
  }
`;

const ArrowIcon = styled.div`
  color: white;
  width: 24px;
  height: 24px;
`;

const CircleIcon = styled.div`
  color: white;
  width: 18px;
  height: 18px;
`;

function ToolDropdown(props) {
  const { open, handleClick, options, value, handleOnChange, handleOnClose } =
    props;

  return (
    <ToolDropdownContainer>
      <SelectBox onClick={handleClick}>
        <Title>{value}</Title>
        {open ? <ArrowIcon /> : <ArrowIcon />}
      </SelectBox>
      <SwipeableDrawer
        anchor="bottom"
        open={open}
        disableBackdropTransition
        disableDiscovery
        onClose={() => requestAnimationFrame(handleOnClose)}
        onOpen={() => {}}
      >
        <ToolHeading
          title="Canada Innovates’ Platform Tools"
          description="Professional tools to support you every step of the way."
          showCloseIcon
        />
        <MenuItemsContainer>
          <MenuItemContainer>
            {options.map((item, index) => (
              <MenuItem
                key={index}
                value={item.text}
                dense
                onClick={() => handleOnChange(item)}
              >
                <ListItemIcon>
                  <CircleIcon />
                </ListItemIcon>
                <ListItemText className="ListItemYo">{item.text}</ListItemText>
              </MenuItem>
            ))}
          </MenuItemContainer>
        </MenuItemsContainer>
      </SwipeableDrawer>
    </ToolDropdownContainer>
  );
}

export default ToolDropdown;
