import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import FeedIcon from "@mui/icons-material/Feed";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";

export const navItems = [
  {
    name: "Live!",
    icon: <ModeStandbyIcon fontSize="small" />,
    pageName: "EVENTS",
    color1: "#F4CF08", // Pink
    color2: "#0097A7", // Cyan for depth
  },
  {
    name: "Progress",
    icon: <AutoGraphIcon fontSize="small" />,
    pageName: "PROGRESS",
    color1: "#F4CF08", // Pink
    color2: "#0097A7", // Cyan for depth
  },
  {
    name: "Files",
    icon: <FeedIcon fontSize="small" />,
    pageName: "DOCUMENTS",
    color1: "#F4CF08", // Pink
    color2: "#0097A7", // Cyan for depth
  },
  // {
  //   name: "Network",
  //   icon: <PublicOutlinedIcon fontSize="small" />,
  //   pageName: "Network",
  //   color1: "#F4CF08", // Pink
  //   color2: "#0097A7", // Cyan for depth
  // },
  {
    name: "Resources",
    icon: <WorkspacesIcon fontSize="small" />,
    pageName: "RESOURCES",
    color1: "#F4CF08", // Pink
    color2: "#0097A7", // Cyan for depth
  },
];
