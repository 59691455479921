import React, { useContext, useEffect } from "react";
import AppContext from "AppContext";
import styled from "styled-components";
import { Label } from "./siteutilities";
import { useParams, useNavigate } from "react-router-dom";


const BlogReturn = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  align-self: flex-start;
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  @media screen and (max-width: 768px) {
    // margin-bottom: 25px;
  }
  /* Custom Scrollbar Styles */
  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
`;

const Blog = styled.div`
  // background: #f7f7f7;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-width: 800px;
  line-height: 25px;
  padding: 0px 30px 0px 10px;
  font-size: 15px;
  align-self: flex-start;
  figure {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  img {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  figcaption {
    text-align: center;
    font-size: 13px;
  }
  code {
    width: 100%;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  p {
    font-size: 16px;
  }
  iframe {
    width: 99% !important;
    margin: 0 auto;
    height: 295px;
  }
  figure {
    margin: 20px 0px;
  }
  @media screen and (max-width: 430px) {
    width: 105%;
    margin-left: -10px;
  }

  @media screen and (max-width: 768px) {
    padding: 25px;
    margin-top: 20px;
    padding-top: 0px;
  }
  @media screen and (min-width: 768px) {
  }
`;

// const BlogHeader = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 3px;
//   margin: 0px 20px 20px;
// `;

const BlogHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.lgray};
  padding: 0px 0px 20px;
  margin-bottom: 20px;
  min-height: 80px;
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    position: sticky;
    top: 0;
    background: ${(props) => props.theme.colors.white};
    z-index: 10;
    transition: all 0.2s;
    padding-top: 40px;
    background: white;
  }
`;

const MetaContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
  justify-content: space-between;
`;

const AuthorImage = styled.div`
  background: url(${(props) => props.src});
  background-size: contain;
  width: 95px;
  // height: 80px;
  background-repeat: no-repeat;
`;

const MetaData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  line-height: 21px;
  span {
    line-height: 15px;
    opacity: 0.5;
  }
`;

const BlogTitle = styled.div`
  font-size: 21px;
  font-weight: 900;
  font-family: roboto;
  text-transform: uppercase;
  margin-bottom: 10px;
  // max-width: 80%;
  font-family: monospace;
  display: flex;
  flex: 2;
`;

const RecentPosts = styled.div`
  box-sizing: border-box;
  margin-left: -20px;
  width: calc(100% + 20px);
  border-radius: 15px;
  background: #f2f2f2;
  padding: 20px 20px 40px 30px;
`;

const SubHeading = styled(Label)`
  margin-left: 0px;
  display: flex;
  margin-bottom: 30px;
  animation: pulse 5s infinite ease-in-out;
  @keyframes pulse {
    0% {
      background-color: ${(props) => props.theme.colors.darkgrey};
      color: black;
    }
    50% {
      background-color: ${(props) => props.theme.colors.black};
      color: white;
    }
    100% {
      background-color: ${(props) => props.theme.colors.darkgrey};
      color: black;
    }
  }
`;

const RecentPostItem = styled.div`
  margin-bottom: 10px;
  margin-top: 0px;
  cursor: pointer;
  padding-left: 10px;
  box-sizing: border-box;
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
    color: rgb(0, 203, 255);
    cursor: pointer;
    border-left: 2px solid rgb(0, 203, 255);
    padding-left: 10px;
    transition: all 0.2s;
  }
`;

const BlogPost = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const { blogPosts, setSelectedPost, selectedPost } = useContext(AppContext);
  const navigate = useNavigate(); // Add useNavigate for navigation

  useEffect(() => {
    // Find the post with the given slug and set it as the selected post
    if (slug && blogPosts.length > 0) {
      const post = blogPosts.find((post) => post.slug === slug);
      if (post) {
        setSelectedPost(post);
      }
    }
  }, [slug, blogPosts, setSelectedPost]);

  const handleRecentPostClick = (post) => {
    // Update the URL to match the clicked post and set it as the selected post
    setSelectedPost(post);
    navigate(`/news/${post.slug}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const formatDateSlug = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  if (!selectedPost) {
    return null;
  }

  return (
    <BlogReturn className="BlogReturn">
      <Blog className="Blog">
        <BlogHeader className="BlogHeader">
          <MetaContentWrapper className="MetaContentWrapper">
            <MetaData>
              <BlogTitle className="BlogTitle">{selectedPost.title}</BlogTitle>
              <span>{formatDateSlug(selectedPost.published_at)}</span>
              <span>
                By{" "}
                {selectedPost.authors.map((author) => author.name).join(", ")}
              </span>
            </MetaData>
            <AuthorImage
              className="author"
              src={selectedPost.authors
                .map((author) => author.profile_image)
                .join(", ")}
              alt="author"
            />
          </MetaContentWrapper>
        </BlogHeader>
        <div dangerouslySetInnerHTML={{ __html: selectedPost.html }} />

        <RecentPosts>
          <SubHeading>Read More?</SubHeading>
          <div>
            {blogPosts
              .filter((post) => post.id !== selectedPost.id)
              .slice(0, 3)
              .map((post) => (
                <RecentPostItem
                  key={post.id}
                  onClick={() => handleRecentPostClick(post)}
                >
                  {post.title} - {formatDateSlug(post.published_at)}
                </RecentPostItem>
              ))}
          </div>
        </RecentPosts>
      </Blog>
    </BlogReturn>
  );
};

export default BlogPost;