import { useContext } from "react";
import AppContext from "AppContext";
import LeftBar from "components/leftbar";
import styled, { css } from "styled-components";

const Section = styled.section`
  text-align: center;
  padding: 0px;
  background: white;
  width: 100%;
  // height: calc(100vh - 80px);
  display: flex;
  flex-direction: row;
  // overflow: hidden;
  // @media screen and (max-width: 768px) {
  //   flex-direction: column;
  // }
  ${(props) =>
    props.platform &&
    css`
      max-width: 100%;
    `}

  @media screen and (max-width: 640px) {
    height: 100%;
    position: fixed;
    width: 100%;
  }
`;

export default function SectionWrapper(props) {
  const { children } = props;
  const { platformApp } = useContext(AppContext);

  return (
    <Section platform={platformApp} className="SectionWrapper">
      <LeftBar />
      {children}
    </Section>
  );
}
