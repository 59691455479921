import { useContext } from "react";
import { SwipeableDrawer as SwipeableDrawerMui } from "@mui/material";
import AppContext from "AppContext";
import styled from "styled-components";
import Panels from "views/panels/panels";
import ToolHeading from "views/tools/ToolHeading";

const widthProps = {
  mobile: "430px",
  tablet: "calc(768px - 30px)",
  "full-width": "calc(100% - 325px)",
};

const SwipeableDrawer = styled(SwipeableDrawerMui)`
 @media screen and (min-width: 640px) {
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
  }
  .MuiDrawer-paper {
    background-color: transparent;
    height: 60%;
    overflow: hidden;
  @media screen and (min-width: 768px) {
      position: absolute !important;
      width: ${(props) => props.width || "auto"};
      right: 30px;
      bottom: 90px;
      padding-top: 0px;
      left: unset;
      transition-property: all !important;
    }
  @media screen and (max-width: 640px) {
      height: 90%;
      width: 100%;
    }
  .MuiBackdrop-root {
    opacity: 0 !important;
  }
`;

const ToolContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: transparent;
`;

const PanelsContainer = styled.div`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-top: 85.52px;
  box-sizing: border-box;
  @media screen and (min-width: 640px) {
    padding-top: 80px;
  }
  height: 100%;
  box-shadow: 0px 0px 5px lightgray;
  background: white;
`;

export default function ToolContent(props) {
  const { isMobile } = useContext(AppContext);
  const { toolItem, open, handleOnClose } = props;

  return (
    <SwipeableDrawer
      id="tool-popup"
      anchor="bottom"
      width={widthProps[toolItem?.width]}
      open={open}
      disableBackdropTransition
      disableDiscovery
      onClose={() => (isMobile ? requestAnimationFrame(handleOnClose) : null)}
      hideBackdrop
      onOpen={() => {}}
    >
      <ToolContainer>
        <ToolHeading
          title={toolItem?.text}
          description={toolItem?.subtitle}
          showCloseIcon
        />
        <PanelsContainer>
          <Panels toolItem={toolItem} showFilters={toolItem?.showFilters} />
        </PanelsContainer>
      </ToolContainer>
    </SwipeableDrawer>
  );
}
