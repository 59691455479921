import styled, { css } from "styled-components";

const PanelReturn = styled.div`
  background: white;
  padding: 30px;
  width: 100%;
  height: calc(100% - 25px);
  box-sizing: border-box;
  text-align: left;
  border-radius: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  height: 100%;
  max-height: calc(100%);
  &.panel2 {
    flex: 1.8;
  }
  &.panel3 {
    flex: 3;
  }
  @media screen and (min-width: 640px) {
    height: 100%;
  }
  ${(props) =>
    props.$toolItem
      ? css`
          @media screen and (min-width: 640px) {
            background: yellow;
            max-height: unset;
          }
          padding: 36px;
        `
      : css`
          box-shadow: 0px 0px 5px lightgrey;
        `}
  @media screen and (max-width: 640px) {
    padding: 35px 25px;
    border-radius: ${(props) =>
      props.$showFilters || props.$toolItem ? "5px" : "3px 3px 0px 0px"};
  }
`;

const Title = styled.span`
  font-family: 'pt sans';
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  letter-spacing: 1px;
  color: black;
  @media screen and (max-width: 640px) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 56px);
    display: inline-block;
  }
}
`;

export default function Panel(props) {
  const { toolItem, showFilters, title, className, panel2 } = props;

  return (
    <PanelReturn
      className={className}
      toolItem={toolItem}
      showFilters={showFilters}
    >
      <Title>{toolItem?.text || title}</Title>
      {panel2}
    </PanelReturn>
  );
}
