import Crop75Icon from "@mui/icons-material/Crop75";
import CropDinIcon from "@mui/icons-material/CropDin";
import styled from "styled-components";

const ToolHeadingContainer = styled.div`
  position: absolute;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 21px 20px 22px;
  width: 100%;
  background: white;
  box-shadow: 0px 0px 5px lightgray;
  z-index: 1;
  box-sizing: border-box;
  @media screen and (max-width: 640px) {
    padding: 26px 18px 22px;
    box-shadow: unset;
    background: black;
  }
`;

const Title = styled.span`
  font-family: "Roboto";
  font-weight: 700;
  font-size: 17px;
  line-height: 20.29px;
  letter-spacing: -0.32px;
  color: black;
  @media screen and (max-width: 640px) {
    color: white;
  }
`;

const Description = styled.p`
  font-family: pt sans;
  font-weight: 700;
  font-size: 14px;
  line-height: 15.53px;
  letter-spacing: -0.32px;
  margin: 0;
  margin-top: 5px;
  @media screen and (max-width: 640px) {
    color: white;
    font-weight: 400;
  }
`;

const CloseDrawerIcon = styled.span`
  display: inline-block;
  position: absolute;
  top: 28px;
  right: 18px;
  color: white;
  @media screen and (min-width: 640px) {
    color: black;
  }
`;

export default function ToolHeading(props) {
  const {
    title,
    description,
    fullHeightDrawer,
    setFullHeightDrawer,
    showCloseIcon,
  } = props;

  return (
    <ToolHeadingContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {showCloseIcon && (
        <CloseDrawerIcon>
          {!fullHeightDrawer ? (
            <CropDinIcon onClick={() => setFullHeightDrawer(true)} />
          ) : (
            <Crop75Icon onClick={() => setFullHeightDrawer(false)} />
          )}
        </CloseDrawerIcon>
      )}
    </ToolHeadingContainer>
  );
}
