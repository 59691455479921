import React from "react";
import styled from "styled-components";
//

const Events = styled.div`
  background: white;
  padding: 25px 15px 25px 15px;
  border-radius: 3px;
  text-align: left;
  height: 100%;
  // min-height: 285px;
  overflow: hidden;
  h4 {
    margin: 5px 0px 20px;
    text-decoration: underline;
    font-size: 16px;
  }
`;

const EventList = styled.div`
  height: 100%;
  overflow-y: scroll;
  padding-right: 15px;
`;

const EventItem = styled.div`
  display: flex;
  background: white;
  margin-bottom: 10px;
  gap: 5px;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  &:hover {
    background: #f2f2f2;
  }
  &:last-of-type {
    // margin-bottom: 40px;
  }
`;

const EventDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 10px;
  width: 100%;
`;

const EventImage = styled.div`
  width: 100%;
  max-width: 85px;
  height: 60px;
  box-sizing: border-box;
  background: #f9f9f9;
  border-radius: 5px;
  background: url(${(props) => props.image});
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 1px;
`;

const EventDate = styled.div`
  font-size: 21px;
  font-weight: bold;
  margin-top: 2px;
`;

const EventName = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const ProgressEvents = () => {
  const events = [
    {
      id: 1,
      name: "Getting Started with Intake and more.",
      date: "Sept. 15",
      image: "images/profile.png",
    },
    {
      id: 2,
      name: "Intake Session",
      date: "Sept. 27",

      image: "images/women.png",
    },
    {
      id: 3,
      name: "Programs Start",
      date: "Oct. 03",
      image: "images/profile.png",
    },
  ];

  return (
    <Events>
      <h4>What's Happening?</h4>
      <EventList>
        {events.map((event) => (
          <EventItem key={event.id}>
            <EventImage image={event.image} />
            <EventDetails>
              <EventDate>{event.date}</EventDate>
              <EventName>{event.name}</EventName>
            </EventDetails>
          </EventItem>
        ))}
      </EventList>
    </Events>
  );
};

export default ProgressEvents;
