import { useState, useEffect } from "react";
import { supabase } from "supabaseClient";

export const useRegistration = () => {
  const [selectedLocation, setSelectedLocation] = useState("");
  const [email, setEmail] = useState("Email Address");
  const [connecting, setConnecting] = useState(false);
  const [waitingForOtp, setWaitingForOtp] = useState(false);
  const [signInError, setSignInError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("Request a Link");

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    if (loading) {
      setSubmitMessage("Request in Progress...");
    } else if (waitingForOtp) {
      setSubmitMessage("Check your email!");
    }
  }, [loading, waitingForOtp]);

  const handleLocation = (event) => {
    event.preventDefault();
    setSelectedLocation(event.target.value);
    console.log("value is set to " + event.target.value);
  };

  const handleEmail = (event) => {
    event.preventDefault();
    const emailValue = event.target.value;
    setEmail(emailValue);
    console.log("Email: " + emailValue);
  };

  const handleRegistration = async (event) => {
    event.preventDefault();

    // Simple email validation
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    if (!emailRegex.test(email)) {
      setSignInError("Check email...");
      return;
    }

    // Set connecting to true before making the request
    setConnecting(true);
    setLoading(true);
    await delay(2000); // Introduce a delay before stopping loading

    console.log("Location: " + selectedLocation);
    console.log("Email: " + email);

    let { data, error } = await supabase.auth.signInWithOtp({
      email: email,
      options: {
        redirectTo: "https://canadainnovates.org/",
        shouldCreateUser: false,
      },
    });

    if (error) {
      setConnecting(false);
      setLoading(false);
      setDisabled(true);
      setSubmitMessage("Check your Email");
      console.error("Error signing in:", error);
      setSignInError("Wait & Try Again.");
      return;
    }
    // // Set waitingForOtp to true
    if (data) {
      setWaitingForOtp(true);
      setLoading(false);
      setDisabled(true);
      console.log("Signed in:", data);
    }
  };

  return {
    selectedLocation,
    setSelectedLocation,
    email,
    setEmail,
    handleLocation,
    handleEmail,
    handleRegistration,
    waitingForOtp,
    setWaitingForOtp,
    signInError,
    loading,
    disabled,
    submitMessage,
  };
};
