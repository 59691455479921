import { useContext, useEffect, useState } from "react";
import AppContext from "AppContext";
import LiveDateTimeDisplay from "utilities/LiveDateTime";
// import GoogleSignInButton from "utilities/googleButton";
import Profile from "pages/login/profile";
import styled, { keyframes, css } from "styled-components";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useRegistration } from "utilities/auth/handleRegistration";
import React from "react";

const isWindows = /Windows/.test(navigator.userAgent);

const Login = styled.div`
  display: flex;
  background-image: url("/images/orglogo.png");
  background-repeat: repeat;
  background-size: 55%;
  // background-position-x: 75%;
  background-position-y: 45%;
  height: calc(100vh);
  width: 100%;
  justify-content: center;
  padding-right: 200px;
  align-items: center;
  box-sizing: border-box;
  @media (max-width: 640px) {
    padding: 0px 20px;
  }
`;

const AuthContainer = styled.div`
  padding: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: white;
  // border: 1px solid lightgray;
  z-index: 1;
  width: 400px;
  box-shadow: 0px 4px 20px 10px rgba(150, 150, 150, 0.12);
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    width: 100%;
    flex: 1;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .linkToHome {
    color: black;
    border: 2px solid black;
    border-radius: 5px;
    padding: 10px 5px;
    text-align: center;
    text-decoration: none;
    margin: 0px 15px;
    margin-top: -10px;
    z-index: 5;
    max-width: 295px;
    box-sizing: border-box;
    &:hover {
      background: black;
      color: white;
      text-decoration: italic;
    }
  }
`;

const CanadaInnovatesLogo = styled.img`
  width: 150px;
  display: flex;
  margin: 5px 0px 15px;
`;

const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 21px;
  width: 100%;
  .Welcome {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 0px;
    margin-bottom: -15px;
  }
  .LiveDateTime {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 17px;
  }
`;

const ContactContent = styled.div`
  display: flex;
  flex-direction: column;
  font-family: PT Sans;
  font-style: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: 400;

  span {
    display: flex;
  }
  .Contact {
    color: #373737;
    font-size: 19px;
    font-weight: 700;
    margin-top: 5px;
  }
  .Email {
    color: #005bf0;
    font-size: 14px;
    text-decoration-line: underline;
    margin-top: 2px;
  }
  .Chat {
    color: #8b8b8b;
    text-decoration-line: underline;
    margin-left: 5px;
  }
  .Or {
    color: #373737;
    margin-top: 20px;
  }
`;

const pulseAnimation = keyframes`
  0% {
    background-color: black; // Original background color
  }
  50% {
    background-color: red; // Change to red
    color: white;
  }
  100% {
    background-color: black; // Back to original
  }
`;

const Submit = styled.div`
  font-family: DIN CONDENSED, "PT Sans";
  text-transform: uppercase;
  font-size: 24px;
  letter-spacing: 1px;
  background: black;
  color: white;
  width: 100%;
  height: 70px;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 15px;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 23px 20px 20px;
  padding-top: ${(props) => (isWindows ? "20px" : "23px")};
  letter-spacing: 2px;
  &:hover {
    background: red !important;
    cursor: pointer;
  }
  ${(props) =>
    props.$loading &&
    css`
      animation: ${pulseAnimation} 2s infinite ease-in-out; // Apply pulse animation
    `}

  // Styles when disabled
  ${({ disabled }) =>
    disabled &&
    css`
      color: lightgray; // Change text color to indicate disabled
      pointer-events: none; // Prevent pointer events when disabled
    `}
`;

// const GoogleButton = styled(GoogleSignInButton)``;

function LoginContainer() {
  const { platformUser } = useContext(AppContext);
  const {
    handleEmail,
    handleRegistration,
    waitingForOtp,
    loading,
    disabled,
    submitMessage,
    setSubmitMessage,
  } = useRegistration();

  console.log("waiting for otp = " + waitingForOtp);
  return (
    <Login className="Login">
      <AuthContainer className="AuthContainer">
        {!platformUser ? (
          <LoginContent className="LoginContent">
            <CanadaInnovatesLogo
              alt="Canada Innovates"
              src="/images/orglogo.png"
            />
            <span className="Welcome ,">
              {platformUser ? "Welcome" : "Already have an Account?"}
            </span>
            <LiveDateTimeDisplay />
            {/* <GoogleButton onClick={signInWithGoogle} /> */}
            <FormControl>
              <TextField
                id="email-id"
                onChange={handleEmail}
                placeholder="Email Address"
              />
              <Submit
                $loading={loading}
                disabled={disabled || loading}
                onClick={handleRegistration}
              >
                {console.log("your submit message" + submitMessage)}
                {submitMessage}
              </Submit>
            </FormControl>
            <ContactContent className="ContactContent">
              <span className="Contact">👩🏾‍💻 Don't have an account?</span>
              <span>
                <Link
                  style={{ textDecoration: "none" }}
                  className="Email"
                  to="/"
                >
                  <b
                    style={{
                      color: "grey",
                      fontWeight: "400",
                      textDecoration: "none",
                      marginLeft: "28px",
                    }}
                  >
                    Register
                  </b>
                  &nbsp;
                  <b style={{ color: "red", textDecoration: "underline" }}>
                    HERE!
                  </b>
                </Link>
              </span>
            </ContactContent>
          </LoginContent>
        ) : (
          <>
            <Profile />
            <Link className="linkToHome" to="/">
              <b>Looking for the Homepage?</b>
            </Link>
          </>
        )}
      </AuthContainer>
    </Login>
  );
}

export default LoginContainer;
