import React, { useState } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { Form } from "../../../node_modules/react-router-dom/dist/index";
import { Counter } from "website/counter";

import ProfileTabs from "./profiletabs";

const ProfileDashboard = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  flex-wrap: wrap;
  gap: 40px;
  overflow: hidden;
  @media (max-width: 768px) {
    flex-gap: 0px;
  }
  margin-bottom: -10px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  flex: 2;
  max-width: 675px;
  flex-shrink: 1;
  border-radius: 3px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 280px;
  flex-shrink: 0;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  height: calc(100% - 90px);
  height: 100%;
`;

const ContentItems = styled.div`
  overflow-y: scroll;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

const Timer = styled.div`
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  // box-shadow: 0px 0px 10px 2px #d4d4d43d;
  border-radius: 5px;
  height: 90px;
`;

const ContentHeading = styled.div`
  width: 100%;
  height: 90px;
  text-align: left;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
  backround: white;
  background: #f9f9f9;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: left;
  font-size: 16px;
  padding: 20px;
  height: 100%;

  h3 {
    font-size: 15px;
  }
  p {
    font-size: 14px;
  }
`;

const Link = styled.a`
  text-decoration: underline;
  font-size: 14px;
`;

const MainImage = styled.div`
  background-image: url("images/profile.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 200px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const ContentItem = styled.div``;

const Forms = styled.div`
  width: 100%;
  padding: 15px 20px 5px;
  margin: -15px -15px -15px -10px;
  overflow: scroll;
`;

const HeaderContainer = styled.div``;

const Header = styled.div`
  border-radius: 5px;
  background: #00c371;
  margin-bottom: 20px;
  color: #fff;
  font-family: "PT Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 17px 33px;
  margin: 15px 0px 35px;
  text-align: left;
`;

const Form1 = styled.div`
  margin-bottom: 10px;
`;

const Form2 = styled.div`
  margin-bottom: 10px;
`;

const Form3 = styled.div`
  margin-bottom: 10px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 0apx;
  padding: 30px;
  box-shadow: 0px 0px 5px 1px #f1f1f1;
  box-sizing: border-box;
  border-radius: 0px;
  text-align: left;
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px 2px rgba(212, 212, 212, 0.24);
  box-sizing: border-box;
  margin-bottom: 20px;
  &:first-of-type {
    border-radius: 5px 5px 0px 0px;
  }
  &:last-of-type {
    border-radius: 0px 0px 5px 5px;
  }
  h1 {
    font-size: 21px;
    margin: 8px 0px 25px;
  }
  
  &:nth-child(even) {
  
`;

const InputContainer = styled.div`
  margin-bottom: 10px;
`;

const Input = styled(TextField)`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  // width: 100%;
  margin-bottom: 20px;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 6px 16px 6px 16px;
  gap: 8px;
  height: 40px;
  text-transform: uppercase;

  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4;
  text-align: left;
  background: black;
`;

const ProfileForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
  };

  return (
    <ProfileDashboard>
      <Left>
        <HeaderContainer>
          <Header>Welcome to Intake</Header>
        </HeaderContainer>
        {/* <ProfileTab s /> */}
        <Forms>
          <FormContainer>
            <h1>Your Company</h1>
            <form>
              <InputContainer>
                <Input
                  type="text"
                  label="Company Name"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  label="Company Address"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  label="Company Size"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <Button type="submit">Save Changes</Button>
            </form>
          </FormContainer>
          <FormContainer>
            <h1>Why are you applying?</h1>
            <form>
              <InputContainer>
                <Input
                  type="text"
                  label="Question 1"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  label="Question 2"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  label="Question 3"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <Button type="submit">Save Changes</Button>
            </form>
          </FormContainer>
          <FormContainer>
            <h1>Company Goals</h1>
            <form>
              <InputContainer>
                <Input
                  type="text"
                  label="Goal 1"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  label="Goal 2"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  label="Goal 3"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <Button type="submit">Save Changes</Button>
            </form>
          </FormContainer>
          <FormContainer>
            <h1>Startup Metrics</h1>
            <form>
              <InputContainer>
                <Input
                  type="text"
                  label="Revenue ($)"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  label="User Acquisition (#)"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <InputContainer>
                <Input
                  type="text"
                  label="Conversion Rate (%)"
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                />
              </InputContainer>
              <Button type="submit">Save Changes</Button>
            </form>
          </FormContainer>
        </Forms>
      </Left>
      <Right>
        <Timer>
          <ContentHeading></ContentHeading>
        </Timer>
        <Content>
          <MainImage></MainImage>
          <ContentItems>
            <ContentItem>
              <h3> Getting Started </h3>
              <p>
                Registration will help us do this and prepare you to apply to
                programs.
              </p>
              <Link>Learn more here.</Link>
            </ContentItem>
            <ContentItem>
              <h3> Privacy </h3>
              <p>
                Your profile is encrypted, secret, and safe. Do not share your
                password.
              </p>
              <Link>Privacy policy.</Link>
            </ContentItem>
          </ContentItems>
        </Content>
      </Right>
    </ProfileDashboard>
  );
};

export default ProfileForm;
