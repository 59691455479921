import { Link } from "react-router-dom";
import DoorbellIcon from "@mui/icons-material/Doorbell";
import styled from "styled-components";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useContext } from "react";
import AppContext from "AppContext";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import { useTheme } from "styled-components";

const LeftBarMenu = styled.div`
  width: 65px;
  min-width: 65px;
  display: flex;
  background: white;
  padding: 8px;
  box-sizing: border-box;
  z-index: 2;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  @media screen and (max-width: 640px) {
    width: 65px;
    min-width: 65px;
    transition: width, 0.3s;
  }
`;

const Logo = styled.div`
  height: 50px;
  width: 50px;
  background-image: url("images/orglogo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const Lists = styled.div`
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  opacity: 0.2;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;

const Nav = styled(List)``;

const Organizations = styled(List)`
  display: none;
`;

const ListItem = styled(Link)`
  background-color: #e2e2e2;
  color: black;
  border-radius: 3px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  text-decoration: none;
  background-image: url(${(props) => props.background});
  background-size: contain;
  background-size: 35px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  &:hover,
  &.active {
    background-color: ${(props) => props.theme.colors.dgray};
    color: white;
    color: ${(props) => props.color};
    cursor: default;
  }
  &.active {
    background-color: black;
  }
`;

// const Name = styled.span`
//   text-transform: uppercase;
//   font-size: 8px;
//   font-weight: 600;
//   margin-top: 2px;
// `;

const OrgListItem = styled(ListItem)`
  background-color: white;
  border: 3px solid transparent;
  box-sizing: border-box;
  &:hover,
  &.active {
    background-color: white;
    border-left: 5px solid ${(props) => props.theme.colors.grey};
    margin-left: -8px;
    padding-left: 45px;
    transition: all 0.2s;
  }
  &.active {
    border-left: 5px solid ${(props) => props.theme.primaryColor};
    margin-left: -8px;
    padding-left: 45px;
  }
`;

const organizationItems = {
  Parkdale: {
    name: "Parkdale Centre",
    link: "/alphaflight",
    icon: "",
    image:
      "https://media.licdn.com/dms/image/D560BAQEUtFgR2qF1tg/company-logo_200_200/0/1702063455611/parkdale_centre_for_innovation_logo?e=2147483647&v=beta&t=XsCzAa8jMwmSpWyTJAcrj4za3fakpbnDgZqIbN0Io_8",
  },
  CWF: {
    name: "CWF",
    link: "/alphaflight",
    icon: "",
    image: "images/canadianwomensfoundation.svg",
  },
};

export default function LeftBar(props) {
  const { platformNav, setPlatformNav } = useContext(AppContext);
  const theme = useTheme();

  const navItems = {
    dashboard: {
      name: "Dash",
      link: "/dashboard",
      icon: <DoorbellIcon />,
      image: "",
      color: theme.primaryColor,
    },
    // alphaflight: {
    //   name: "Home",
    //   link: "/alphaflight",
    //   icon: <DashboardRoundedIcon />,
    //   image: "",
    //   color: theme.colors.yellow,
    // },
  };

  const handleClick = (name) => {
    setPlatformNav(name);
  };

  return (
    <LeftBarMenu>
      <Logo className="Logo" />
      <Nav>
        {Object.entries(navItems).map(
          ([key, { color, name, link, icon, image }]) => (
            <ListItem
              key={name}
              to={link}
              onClick={() => handleClick(name)}
              className={platformNav === name ? "active" : ""}
              background={image ? image : null}
              color={color ? color : null}
            >
              {icon ? icon : ""}
              {/* <Name>{name}</Name> */}
            </ListItem>
          )
        )}
      </Nav>
      <HorizontalRuleIcon style={{ color: "lightgray" }} />
      <Lists className="lists disabled">
        <Organizations>
          {Object.entries(organizationItems).map(
            ([key, { link, icon, name, image }]) => (
              <OrgListItem
                key={name}
                to={link}
                onClick={() => handleClick(name)}
                className={platformNav === name ? "active" : ""}
                background={image ? image : ""}
              ></OrgListItem>
            )
          )}
        </Organizations>
      </Lists>
    </LeftBarMenu>
  );
}
