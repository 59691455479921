import { useContext } from "react";
import AppContext from "AppContext";
import { Navigate } from "react-router-dom";

// Proected Routes
export function ProtectedContent({ children }) {
  const { platformUser } = useContext(AppContext);
  return platformUser ? children : <Navigate to="/" replace />;
}

// Redirect Routes
export function RedirectRoute() {
  return <Navigate to="/" replace />;
}

export function useCheckStatusRoute() {
  const { isApproved, platformUser } = useContext(AppContext);
  console.log("isApproved", isApproved);
  return isApproved ? (
    (console.log("isApproved", isApproved), (<Navigate to="/dashboard" />))
  ) : platformUser ? (
    <Navigate to="/profile" />
  ) : (
    <Navigate to="/" />
  );
}
