export const theme = {
  colors: {
    white: "white",
    black: "black",
    lgray: "#f1f1f1",
    grey: "lightgrey",
    dgray: "rgb(61, 61, 61)",
    yellow: "#F4CF08",
    pink: "#DA648F",
  },
  primaryColor: "#F4CF08",

  navColors: [
    {
      color1: "red",
      color2: "blue",
    },
    {
      color1: "green",
      color2: "yellow",
    },
    {
      color1: "orange",
      color2: "purple",
    },
    {
      color1: "pink",
      color2: "gray",
    },
    {
      color1: "brown",
      color2: "teal",
    },
  ],
};
