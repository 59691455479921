import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import check from "assets/check.svg";
import AppContext from "AppContext";
import FetchProfileData from "utilities/auth/fetchProfile";
import { Link } from "react-router-dom";

const Progress = styled.div`
  display: flex;
  flex-direction: column;
  height: 190px;
  border-radius: 3px;
  text-align: left;
  padding: 25px 15px 20px;
  background: white;
  h4 {
    margin: 5px 0px 20px;
    text-decoration: underline;
    font-size: 16px;
  }
`;

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  overflow: scroll;
`;

const Step = styled.div`
  display: flex;
  flex-directino: column;
  align-items: center;
  gap: 10px;
  padding: 5px;
`;

const StepNumber = styled.div`
  display: flex;
  background: #1d1e2f;
  color: white;
  font-weight: 700;
  height: 25px;
  width: 25px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  font-family: Roboto;
  border-radius: 25px;
  border: 5px solid white;
  box-shadow: 0px 0px 5px 2px #e2e2e2;
  img {
    width: 12px;
    height: 12px;
  }
`;

const StepTitle = styled.h5`
  margin: 0px;
  border-radius: 25px;
  padding: 4px 15px 5px;
  font-size: 13px;
  ${(props) => `
    background: ${props.complete ? "#1d1e2f" : "white"};
    border: ${props.complete ? "none" : "2px solid #1d1e2f"};
    color: ${props.complete ? "white" : "black"};

    &:hover {
      background: ${
        props.hasUrl ? "green" : props.complete ? "black" : "white"
      };
      color: white;
      cursor: ${props.hasUrl ? "pointer" : "default"};
    }
  `}
`;


const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover ${StepTitle} {
    background: #ff0000;
    color: white;
  }
`;

export const ProfileProgress = () => {
  const { hasProfile, platformUser } = useContext(AppContext);
  const { profile } = FetchProfileData();

  const stepdata = [
    { title: "Registration", completed: platformUser },
    { title: "Profile Setup", completed: profile, url: "/profile" },
    { title: "Apply", completed: false }, // Placeholder for future condition
  ];

  return (
    <Progress>
      <h4>Welcome to Intake!</h4>
      <Steps>
        {stepdata.map((step, index) => (
          <StyledLink to={step.url ? step.url : ""} key={index}>
            <Step complete={step.completed}>
              <StepNumber>
                {step.completed ? <img src={check} alt="check" /> : index + 1}
              </StepNumber>
              <StepTitle complete={step.completed} hasUrl={!!step.url}>
                {step.title}
              </StepTitle>
            </Step>
          </StyledLink>
        ))}
      </Steps>
    </Progress>
  );
};
