import "./App.css";
import { useMemo, useState, useEffect, React } from "react";
import AppContext from "./AppContext";
import { ThemeProvider } from "styled-components";
import { theme } from "utilities/utils";
import Tools from "views/tools/Tools";
import { supabase } from "supabaseClient";
import BlogPost from "website/homeblog";

export default function App({ children }) {
  // Auth Data
  const [platformUrl, setPlatformUrl] = useState(null);
  const [platformUser, setPlatformUser] = useState(null);

  // Access Permissions
  // ## Registered an Account
  // ## Has a Profile
  // ## Is approved by ADMIN

  const [isRegistered, setIsRegistered] = useState(null);
  const [hasProfile, setHasProfile] = useState(null);
  const [isApproved, setIsApproved] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [userOrganization, setUserOrganization] = useState(null);

  // Content Data
  const [platformData, setPlatformData] = useState(null);
  const [eventsData, setEventsData] = useState([]);

  // Navigation

  const [selectedNavItem, setSelectedNavItem] = useState([]);
  const [platformNav, setPlatformNav] = useState("Dashboard");
  const [platformPageName, setPlatformPageName] = useState(null);
  const [platformActiveTab, setPlatformActiveTab] = useState([]);
  const [platformActiveCard, setPlatformActiveCard] = useState([]);

  // Blog
  const [blogPosts, setBlogPosts] = useState([]);
  const [blogPages, setBlogPages] = useState([]);
  const [selectedPost, setSelectedPost] = useState();
  const [selectedLink, setSelectedLink] = useState();

  // Presentation
  const [isMobile, setIsMobile] = useState(false);

  // Set Url
  const baseurl = process.env.REACT_APP_SERVER_URL;
  const fullurl = `${baseurl}`;
  useEffect(() => {
    setPlatformUrl(fullurl);
  }, [fullurl, platformUrl]);

  // Auth Listner
  useEffect(() => {
    const authListener = supabase.auth.onAuthStateChange((_event, session) => {
      if (session) {
        setPlatformUser(session.user);
      } else {
        setPlatformUser(false);
      }
    });
    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  // set hasProfile to true if a user has a profile in the profiles table and the waitlist flag is set to true

  useEffect(() => {
    let isUser = platformUser ? true : false;

    if (isUser) {
      const fetchProfile = async () => {
        try {
          const { data, error } = await supabase
            .from("profiles")
            .select()
            .eq("id", platformUser.id)
            .single();
          if (error) {
            // console.error("Fetch error:", error);
            return;
          }

          if (data) {
            setHasProfile(true);
          } else {
            setHasProfile(false);
          }
        } catch (err) {}
      };
      fetchProfile();
    } else {
    }
  }, [platformUser]);

  // TPL Data
  useEffect(() => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/tpl`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("tpl", data);
        setPlatformData(data);
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  const contextValue = useMemo(
    () => ({
      platformUser,
      setPlatformUser,
      isRegistered,
      setIsRegistered,
      hasProfile,
      setHasProfile,
      isApproved,
      setIsApproved,
      platformUrl,
      setPlatformUrl,
      platformData,
      setPlatformData,
      platformNav,
      setPlatformNav,
      platformPageName,
      setPlatformPageName,
      platformActiveTab,
      setPlatformActiveTab,
      platformActiveCard,
      setPlatformActiveCard,
      eventsData,
      setEventsData,
      isMobile,
      setIsMobile,
      selectedNavItem,
      setSelectedNavItem,
      selectedPost,
      setSelectedPost,
      selectedLink,
      setSelectedLink,
      blogPosts,
      setBlogPosts,
      blogPages,
      setBlogPages,
      userRole,
      setUserRole,
      userOrganization,
      setUserOrganization,
    }),
    [
      selectedNavItem,
      platformUrl,
      platformUser,
      isRegistered,
      hasProfile,
      isApproved,
      platformData,
      platformNav,
      platformPageName,
      platformActiveTab,
      platformActiveCard,
      eventsData,
      isMobile,
      selectedPost,
      selectedLink,
      blogPosts,
      blogPages,
      userRole,
      userOrganization,
    ]
  );

  useEffect(() => {
    // console.log(platformPageName);
    // console.log(platformData);
  }, [platformPageName, platformData]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider value={contextValue}>
        <div className="App">{children}</div>
        {isApproved ? <Tools /> : null}
        {/* <Tools /> */}
      </AppContext.Provider>
    </ThemeProvider>
  );
}
