import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AppContext from "AppContext";
import styled from "styled-components";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";

const BlogList = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px 20px 20px 40px;
  box-sizing: border-box;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-transform: capitalize;
  font-family: roboto;
  line-height: 21px;
  background: ${(props) => (!props.$showMenu ? "white" : "black")};
  color: white;
  border-radius: 10px 0px 0px 10px;
  transition: all 0.2s;
  justify-self: flex-start;
  @media screen and (max-width: 768px) {
    border-radius: 10px 10px 0px 0px;
    padding: 0px;
    &:hover {
      background: rgb(32, 31, 31);
      svg {
        color: rgb(0, 203, 255);
        transition: color, 0.2s;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    text-transform: capitalize;
    margin-left: 10px;
    margin-top: 20px;
    overflow-y: scroll;
  }
  &:hover {
    cursor: pointer;
    color: #f2f2f2;
  }
  h3 {
    border-radius: 3px;
    max-width: fit-content;
    padding: 2px 10px;
    font-size: 14px;
    color: black;
    font-weight: 400;
  }
`;

const colorArray = [
  "rgb(0, 105, 148)", // Medium Blue
  "rgb(0, 191, 255)", // Deep Sky Blue
  "rgb(32, 178, 170)", // Light Sea Green
  "rgb(72, 209, 204)", // Medium Turquoise
  "rgb(70, 130, 180)", // Steel Blue
];

const warmColorArray = [
  "rgb(255, 99, 71)", // Tomato
  "rgb(255, 165, 0)", // Orange
  "rgb(255, 69, 0)", // Red Orange
  "rgb(255, 140, 0)", // Dark Orange
  "rgb(240, 128, 128)", // Light Coral
];

const Posts = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding-left: 20px;
  font-weight: 500;

  /* Conditionally apply the left border if it's a post */
  border-left: ${(props) =>
    props.$isPost
      ? `2px solid ${colorArray[props.$index % colorArray.length]}`
      : "none"};

  u {
    &:hover {
      cursor: pointer;
    }
  }
  ${(props) => props.$selected && "color: rgb(0, 203, 255);"}
  // padding: 5px 5px 8px 20px;
  box-sizing: border-box;

  &:hover {
    color: rgb(0, 203, 255);
    // background: rgb(32, 31, 31);
    transition: all 0.2s;
  }
`;

const MobileSelect = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  padding-bottom: ${(props) => (!props.$showMenu ? "20px" : "20px")};
  background: black;
  background-image: url(https://images.unsplash.com/photo-1531058240690-006c446962d8?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: cover;
  background-position: top;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const MobileBlogMenu = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  // background: rgb(32, 31, 31);
  background-image: url(https://images.unsplash.com/photo-1531058240690-006c446962d8?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-position: center;
  box-sizing: border-box;
  padding: 10px;
  height: calc(100vh - 200px);
  .inner {
    height: calc(100vh - 270px);
    max-width: 100%;
    margin: 0 auto;
    padding: 0px 5px;
    box-sizing: border-box;
    border-radius: 5px;
    background: black;
    max-height: calc(100vh - 100px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 79%;
    border-radius: 15px;
    ul {
      &::-webkit-scrollbar {
        width: 5px !important; /* Set width explicitly */
        background-color: black !important; /* White background for the scrollbar */
        border-radius: 10px !important;
      }

      &::-webkit-scrollbar-thumb {
        background-color: white !important; /* White scrollbar thumb */
        border-radius: 10px !important;
        border: 1px solid #ccc; /* Adding a subtle border to make it more visible */
      }
    }
  }
  h3 {
    color: white;
  }
`;

const DesktopRoll = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  background: #f2f2f2;
  background-image: url(https://images.unsplash.com/photo-1531058240690-006c446962d8?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
  background-size: contain;
  @media screen and (max-width: 768px) {
    display: none;
  }
  h3 {
    background: black;
    padding: 5px 10px;
    color: white;
    margin-left: 10px;
    border-radius: 5px;
    font-weight: 600;
  }
`;

const DesktopPosts = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.25;
  overflow: auto;
  ul {
    margin-top: 0px;
    margin-bottom: 0px;
    background: black;
    margin-right: 10px;
    gap: 0;
    padding: 20px 0px 15px;
    border-radius: 5px;
  }
`;

const Resources = styled.div`
  display: flex;
  flex-direction: column;
  // flex: 0.85;
  overflow: hidden;
  justify-content: flex-end;
  ul {
    margin-top: 0px;
    margin-bottom: 0px;
    background: white;
    color: black;
    border-radius: 5px;
    padding: 8px 8px 12px 13px;
    box-sizing: border-box;
    font-size: 13px;
    overflow: auto;
    position: relative;
    background: #f7f7f7;
    margin-bottom: 10px;
    margin-right: 10px;
    &:hover {
      div {
        background: none;
      }
    }

    div {
      margin-bottom: 0px;
      padding-left: 5px; /* Add space for bullet */
      padding-bottom: 0px;
      position: relative;

      // &::before {
      //   content: "";
      //   position: absolute;
      //   left: 3px;
      //   top: 60%;
      //   transform: translateY(-50%);
      //   width: 6px;
      //   height: 6px;
      //   border-radius: 50%;
      //   background-color: black;
      // }
    }
  }
`;

const ProfileNavItem = styled.div`
  color: white;
  background-color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.2s;
  margin-bottom: 10px;
  @media screen and (max-width: 1024px) {
    margin-top: 10px;
  }
  &:hover {
    background-color: white;
    color: rgb(0, 203, 255);
    svg {
      color: rgb(0, 203, 255);
    }
  }
  svg {
    margin-top: 2px;
    margin-right: 8px;
  }
`;

const DropdownIcon = styled(ExpandCircleDownIcon)`
  margin-left: auto; /* Pushes icon to the right end */
  transition: transform 0.5s;
  transform: ${(props) => (props.$menuOpen ? "rotate(180deg)" : "rotate(0deg)")};
`;

const HomeBlogRoll = () => {
  const {
    setSelectedPost,
    blogPosts,
    blogPages,
    setBlogPosts,
    setBlogPages,
    setSelectedNavItem,
    selectedPost,
    setSelectedLink,
  } = useContext(AppContext);

  const apiUrl = "https://canada-innovates.ghost.io/ghost/api/v3/content";
  const apiKey = "f9e20cb127d729cbc5dcd5702b";

  // State to manage selected content and menu visibility
  const [selectedContent, setSelectedContent] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation(); // Get the current location
  const navigate = useNavigate(location);

  const NavUpdate = (item) => {
    setSelectedLink("");
    setSelectedPost("");
    navigate(`${item}`);
  };

  useEffect(() => {
    const handlePathChange = () => {
      const currentPath = location.pathname;

      if (currentPath.startsWith("/news") && blogPosts.length > 0) {
        const matchedPost = blogPosts.find((post) =>
          currentPath.includes(post.slug)
        );
        if (matchedPost && selectedPost?.id !== matchedPost.id) {
          setSelectedContent(matchedPost);
          setSelectedPost(matchedPost);
        } else if (!matchedPost && selectedPost?.id !== blogPosts[0]?.id) {
          setSelectedContent(blogPosts[0]);
          setSelectedPost(blogPosts[0]);
        }
      } else {
        const matchedPage = blogPages.find((page) =>
          currentPath.includes(page.slug)
        );
        if (matchedPage && selectedPost?.id !== matchedPage.id) {
          setSelectedContent(matchedPage);
          setSelectedPost(matchedPage);
        }
      }
    };
    handlePathChange();
  }, [location, blogPosts, blogPages, selectedPost, setSelectedPost]);

  // Refs for the mobile menu and mobile select
  const menuRef = useRef(null);
  const selectRef = useRef(null);

  // Fetch posts and pages from the Ghost API
  const fetchContent = useCallback(async () => {
    try {
      const postsResponse = await axios.get(
        `${apiUrl}/posts/?key=${apiKey}&include=tags,authors`
      );
      const pagesResponse = await axios.get(
        `${apiUrl}/pages/?key=${apiKey}&include=tags,authors`
      );
      setBlogPosts(postsResponse.data.posts);
      setBlogPages(pagesResponse.data.pages);
    } catch (error) {
      console.error("Error fetching content from Ghost API:", error);
    }
  }, [
    apiUrl,
    apiKey,
    setBlogPosts,
    setBlogPages,
    selectedContent,
    setSelectedPost,
  ]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  useEffect(() => {
    setSelectedNavItem(null);
  }, [setSelectedNavItem]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        selectRef.current &&
        !selectRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  const formatDateSlug = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  const handleContentClick = (selected) => {
    if (selectedContent?.id !== selected.id) {
      setSelectedPost(selected);
      setSelectedContent(selected);

      // Navigate to the correct route using the slug
      if (blogPosts.some((post) => post.id === selected.id)) {
        navigate(`/news/${selected.slug}`);
      } else if (blogPages.some((page) => page.id === selected.id)) {
        navigate(`/${selected.slug}`);
      }

      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top smoothly
    }
    setShowMenu(false); // Close the mobile menu after selecting a post
  };

  const toggleMobileMenu = (e) => {
    e.stopPropagation();
    setShowMenu((prev) => !prev);
  };

  const renderContentList = (contentArray, isPost = true) => {
    // Sort contentArray by date in descending order (newest to oldest)
    const sortedContentArray = contentArray.sort(
      (a, b) => new Date(b.published_at) - new Date(a.published_at)
    );

    return (
      <ul>
        {sortedContentArray.map((content, index) => (
          <Posts
            className="posts"
            key={content.id}
            $index={index} // Pass index to handle alternating colors
            $isPost={isPost} // Pass isPost to conditionally apply styles
            onClick={() => handleContentClick(content)}
            $selected={selectedContent?.id === content.id}
          >
            <u>{content.title}</u>
            {isPost && content.published_at && (
              <span>{formatDateSlug(content.published_at)}</span>
            )}
          </Posts>
        ))}
      </ul>
    );
  };

  return (
    <BlogList className="BlogList">
      <MobileSelect
        className="MobileSelect"
        ref={selectRef}
        onClick={toggleMobileMenu}
        $selected={!!selectedPost}
        $showMenu={showMenu}
      >
        <u>{selectedPost?.title}</u>
        <span>-</span>
        <span>{formatDateSlug(selectedPost?.published_at)}</span>
        <DropdownIcon $menuOpen={showMenu} />
      </MobileSelect>
      {showMenu && (
        <>
          <MobileBlogMenu className="MobileBlogMenu" ref={menuRef}>
            {/* <ProfileNavItem onClick={() => NavUpdate("/profile")}>
              <ExitToAppOutlinedIcon style={{ transform: "rotate(180deg)" }} />{" "}
              Back to Profile ?
            </ProfileNavItem> */}
            <div className="inner">
              <h3>News v1.0</h3>
              {renderContentList(blogPosts)}
              {renderContentList(blogPages, false)}
            </div>
          </MobileBlogMenu>
        </>
      )}

      <DesktopRoll className="DesktopRoll" $showMenu={showMenu}>
        <DesktopPosts>
          <h3>News v1.0</h3>
          {renderContentList(blogPosts)}
        </DesktopPosts>
        <Resources>
          <h3>Resources</h3>
          {renderContentList([...blogPages].reverse(), false)}
        </Resources>
      </DesktopRoll>
    </BlogList>
  );
};

export default HomeBlogRoll;
