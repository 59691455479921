import React, { useState } from "react";
import styled from "styled-components";
import { useContext } from "react";
import AppContext from "AppContext";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  box-sizing: border-box;
`;

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0 5px;
  gap: 10px;
  @media screen and (max-width: 768px) {
    padding-top: 0px;
    margin-top: -10px;
  }
`;

const Tab = styled.div`
  padding: 10px;
  cursor: pointer;
  background: ${(props) => props.theme.colors.lgray};
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 900;
  border-radius: 20px;
  padding: 5px 12px;
  box-sizing: border-box;
  transition: all, 0.2s;
  &:hover,
  &.active {
    background: black;
    color: white;
  }
`;

const TabsContent = styled.div`
  box-shadow: 0px 0px 13px -4px lightgrey;
  border: solid 1px #f1f1f1;
  justify-content: flex-start;
  display: flex;
  border-radius: 5px;
  // overflow-y: scroll;
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  > div {
    flex-grow: 1;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    text-align: left;
  }
  img {
    width: 75px;
  }
`;

const TodoList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  gap: 15px;
  height: 100%;
  // overflow: scroll;
`;

const TodoItem = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
`;

const TodoLabel = styled.label`
  margin-left: 10px;
`;

const Overview = ({ paneldata }) => {
  return (
    <div>
      <h2>{paneldata.title}</h2>
      {paneldata.meta?.Icon && (
        <img src={paneldata.meta?.Icon} alt={paneldata.Title} />
      )}
      {paneldata.meta?.Image && (
        <img src={paneldata.meta?.Image} alt={paneldata.Title} />
      )}
      <p>{paneldata.description}</p>
      {/* <p>{paneldata.items.Details}</p> */}
    </div>
  );
};

const Todos = ({ paneldata }) => {
  const todoItems = paneldata.items || [];

  const handleCheckboxChange = (index) => {
    // Update the state or perform any other logic when a checkbox is changed
    // You can use the index parameter to identify which checkbox was changed
    console.log("send an API call", index);
  };

  return (
    <div>
      <TodoList>
        {todoItems.map((item, index) => {
          const {
            // "Todo Image": image,
            "Todo Title": title,
            "Todo Button Link": buttonLink,
            "Todo Button": button,
          } = item;
          return (
            <TodoItem key={index}>
              <input
                type="checkbox"
                onChange={() => handleCheckboxChange(index)}
              />
              {/* <img src={image} alt={title} /> */}
              <TodoLabel>{title}</TodoLabel>
              <a href={buttonLink}>{button}</a>
            </TodoItem>
          );
        })}
      </TodoList>
    </div>
  );
};

const ResourceItem = styled.div``;

const ResourceLabel = styled.label``;

const Resources = ({ paneldata }) => {
  const resourceItems = paneldata.items;
  const resourceImage = paneldata.meta?.["Resource Image"];
  const resourceTitle = paneldata.meta?.["Resource Title"];
  const resourceButtonLink = paneldata.meta?.["Resource Button Link"];
  const resourceButton = paneldata.meta?.["Resource Button"];

  return (
    <div>
      {resourceImage && (
        <ResourceItem>
          <img src={resourceImage} alt={resourceTitle} />
          <ResourceLabel>{resourceTitle}</ResourceLabel>
          <a href={resourceButtonLink}>{resourceButton}</a>
        </ResourceItem>
      )}
      {resourceItems?.map((item, index) => {
        const {
          "Resource Image": image,
          "Resource Title": title,
          "Resource Button Link": buttonLink,
          "Resource Button": button,
        } = item;
        return (
          <ResourceItem key={index}>
            <img src={image} alt={title} />
            <ResourceLabel>{title}</ResourceLabel>
            <a href={buttonLink}>{button}</a>
          </ResourceItem>
        );
      })}
    </div>
  );
};

const Documents = ({ paneldata }) => {
  return <div></div>;
};

const Media = ({ paneldata, panel2 }) => {
  const mediaUrl = paneldata.meta?.Media;
  const handleClick = () => {};

  return (
    <div>
      {mediaUrl && (
        <LiteYouTubeEmbed
          id={mediaUrl}
          title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
        />
      )}
      panel2
      <div onClick={handleClick}>{mediaUrl}</div>
    </div>
  );
};

const PanelMain = (props) => {
  const { platformActiveCard } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("Overview");
  const panel2 = props.panel2;

  const paneldata = platformActiveCard;
  console.log("paneldata", paneldata);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  let content;
  switch (activeTab) {
    case "Overview":
      content = <Overview paneldata={paneldata} />;
      break;
    case "Todos":
      content = <Todos paneldata={paneldata} />;
      break;
    case "Docs":
      content = <Documents paneldata={paneldata} />;
      break;
    case "Media":
      content = <Media paneldata={paneldata} />;
      break;
    case "Resources":
      content = <Resources paneldata={paneldata} />;
      break;
    default:
      content = null;
  }

  const tabs = [
    { name: "Overview", condition: paneldata },
    { name: "Todos", condition: paneldata },
    { name: "Docs", condition: paneldata },
    { name: "Resources", condition: paneldata },
    { name: "Media", condition: paneldata },
  ];

  return paneldata ? (
    <Container className="Container" panel2={panel2}>
      <TabsContainer className="TabsContainer">
        {tabs?.map(
          (tab) =>
            tab?.condition && (
              <Tab
                key={tab.name}
                onClick={() => handleTabClick(tab.name)}
                className={activeTab === tab.name ? "active" : ""}
              >
                {tab.name}
              </Tab>
            )
        )}
      </TabsContainer>
      <TabsContent>{content}</TabsContent>
    </Container>
  ) : null;
};

export default PanelMain;
