import styled from "styled-components";

const PanelContainer = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  height: 100%;
  background: white;
`;

function Panel3(props) {
  const { content, panel2 } = props;
  return (
    <PanelContainer panel2={panel2} className="panel3" title="">
      {content}
      {panel2}
    </PanelContainer>
  );
}

export default Panel3;
