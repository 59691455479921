import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/"); // go to homepage. Consider for logged in users to send them to the dashboard
  }, [navigate]);

  // render your 404 page
  return "";
}

export default NotFound;
