import { useContext } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import AppContext from "AppContext";
// import { motion } from 'framer-motion';
import LiveDateTimeDisplay from "utilities/LiveDateTime";
import styled from "styled-components";
import { supabase } from "supabaseClient";

const ProfileCard = styled.div`
  text-align: left;
  box-sizing: border-box;
  // border-top: 5px solid black;
  border-radius: 3px;
  background-color: rgb(255, 251, 251);
  background-color: white;
  z-index: 1;
  flex-direction: column;
  display: flex;
  padding: 15px;
  width: 100%;
  max-width: 325px;
  // height: 110px;
`;

const ProfileAvatar = styled(Avatar)`
  width: 45px !important;
  height: 45px !important;
  border
`;

const SignOut = styled(Button)`
  background: ${(props) => props.theme.colors.black} !important;
  color: ${(props) => props.theme.white} !important;
`;

const ProfileCardContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-weight: bold;
  @media screen and (max-width: 768px) {
    margin: 0px;
    align-content: center;
  }
`;

const HideonMobile = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export default function Profile({ children }) {
  const { setPlatformUser, platformUser, platformUrl } = useContext(AppContext);
  const redirectUrl = `${platformUrl}`;

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setPlatformUser(null);
    window.location.href = redirectUrl;
  };

  return (
    // Wrap the JSX code with a return statement
    <ProfileCard className="ProfileCard">
      <ProfileCardContent>
        {/* <motion.div
        className="Motion"
        initial={{ opacity: 0, x: 0 }}
        animate={{ opacity: 1, x: 0, delay: 0 }}
        transition={{ duration: 0.2, delay: 0 }}
      > */}
        {platformUser?.user?.user_metadata?.avatar_url ? (
          <ProfileAvatar
            // style={{ width: '50px', height: '50px', borderRadius: '25px' }}
            src={
              platformUser?.user_metadata?.avatar_url
                ? platformUser.user_metadata.avatar_url
                : "/images/avatar.png"
            }
            alt="User avatar"
          />
        ) : null}
        <br />
        {/* <HideonMobile> */}
        <div className="Welcome">{platformUser?.user_metadata?.full_name}</div>
        <p className="UserEmail">
          You are logged in as {platformUser?.email}
          <br />
          <LiveDateTimeDisplay />
        </p>
        <SignOut variant="contained" type="button" onClick={handleSignOut}>
          Sign Out ?
        </SignOut>

        {/* </HideonMobile> */}
        {children}

        {/* </motion.div> */}
      </ProfileCardContent>
    </ProfileCard>
  );
}
