import { useContext, useEffect, useMemo, useState } from "react";
import { Tabs as MuiTabs, Tab } from "@mui/material";
import AppContext from "AppContext";
import styled from "styled-components";

const TabsContainer = styled.div`
  background: white;
  border-left: none;
  border-top: 1px solid #f1f1f1;
  box-shadow: 0px 0px 3px 4px #f1f1f1;
  box-sizing: border-box;
  display: inline-block;
  font-family: pt sans;
  font-size: 18px;
  min-height: 60px;
  padding: 0px 0px 0px 15px;
  position: relative;
  text-align: left;
  width: 100%;
  z-index: 1;
  @media screen and (max-width: 768px) {
    height: 40px;
    min-height: 40px;
  }
`;

const Tabs = styled(MuiTabs)`
  display: flex;
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTabs-flexContainer {
    gap: 0px;
  }
  // Tabs
  .MuiTab-root {
    color: gray;
    font-weight: 700;
    font-family: pt sans;
    font-size: 15px;
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: unset;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    gap: 40px;
    height: @media screen and (max-width: 768px) {
      padding: 5px;
    }
    &:hover {
      color: black;
      transition: color, 0.2s;
    }
    &.Mui-selected {
      color: black;
    }
  }
`;

const Tabatha = styled(Tab)`
  @media screen and (max-width: 768px) {
    padding: 0px !important;
  }
`;

function SubNav({ pageName }) {
  const { platformData, platformActiveTab, setPlatformActiveTab } =
    useContext(AppContext);
  const tabs = useMemo(() => {
    // Find the page that matches the provided pageName
    const page = platformData?.pages?.find((sec) => sec.title === pageName);
    console.log("page", page);
    // If a matching page is found, map the headers of each subsection to create tabs
    if (page) {
      return page.sections.map((section, index) => ({
        text: section.title,
        index,
      }));
    }

    return []; // Return an empty array if no matching section is found
  }, [pageName, platformData]);

  const [value, setValue] = useState(tabs[0]?.text || "");

  useEffect(() => {
    // Set the default tab when the component mounts
    if (platformActiveTab === null && tabs[0]?.text) {
      setPlatformActiveTab(tabs[0].text);
    }
  }, [tabs, platformActiveTab, setPlatformActiveTab]);

  const handleChange = (event, newValue) => {
    setPlatformActiveTab(newValue); // Update global state
    setValue(newValue); // Update local state
  };

  useEffect(() => {
    if (platformActiveTab && platformActiveTab.length > 0) {
      console.log("activeTab", platformActiveTab);
    }
  }, [platformActiveTab]);

  return (
    <TabsContainer className="TabsContainer">
      <Tabs
        className="Tabs"
        variant="scrollable"
        scrollButtons={false}
        value={value}
        onChange={handleChange}
      >
        {tabs.map((tab, idx) => (
          <Tabatha
            className="tab"
            key={idx}
            disableRipple
            disableFocusRipple
            value={tab.text}
            label={tab.text}
          />
        ))}
      </Tabs>
    </TabsContainer>
  );
}

export default SubNav;
