import styled from "styled-components";

export const indigenousNations = {
  "Cree Nation": {
    uid: 1,
    name: "Cree Nation",
    image: "🌲",
    communities: [
      { name: "Oujé-Bougoumou", uid: 101 },
      { name: "Mistissini", uid: 102 },
      { name: "Chisasibi", uid: 103 },
      { name: "Waskaganish", uid: 104 },
      { name: "Whapmagoostui", uid: 105 },
      { name: "Waswanipi", uid: 106 },
    ],
  },
  "Inuit Nunangat": {
    uid: 2,
    name: "Inuit Nunangat",
    image: "❄️",
    communities: [
      { name: "Iqaluit", uid: 201 },
      { name: "Inuvik", uid: 202 },
      { name: "Kuujjuaq", uid: 203 },
      { name: "Rankin Inlet", uid: 204 },
      { name: "Arviat", uid: 205 },
      { name: "Nunatsiavut", uid: 206 },
    ],
  },
  "Mi'kmaq Nation": {
    uid: 3,
    name: "Mi'kmaq Nation",
    image: "🦅",
    communities: [
      { name: "Eskasoni", uid: 301 },
      { name: "Listuguj", uid: 302 },
      { name: "Membertou", uid: 303 },
      { name: "Potlotek", uid: 304 },
      { name: "Pabineau", uid: 305 },
      { name: "Lennox Island", uid: 306 },
    ],
  },
  "Haudenosaunee Confederacy": {
    uid: 4,
    name: "Haudenosaunee Confederacy",
    image: "🌳",
    communities: [
      { name: "Six Nations of the Grand River", uid: 401 },
      { name: "Tyendinaga Mohawk Territory", uid: 402 },
      { name: "Akwesasne", uid: 403 },
      { name: "Kahnawake", uid: 404 },
      { name: "Oneida Nation of the Thames", uid: 405 },
      { name: "Onondaga Nation", uid: 406 },
    ],
  },
  "Métis Nation": {
    uid: 5,
    name: "Métis Nation",
    image: "🏞️",
    communities: [
      { name: "Red River Settlement", uid: 501 },
      { name: "Batoche", uid: 502 },
      { name: "St. Laurent", uid: 503 },
      { name: "Lac La Biche", uid: 504 },
      { name: "Île-à-la-Crosse", uid: 505 },
      { name: "St. Albert", uid: 506 },
    ],
  },
};

export const provinces = {
  "First Nations": {
    uid: 16,
    name: "First Nations",
    image: "🏕️",
    cities: [
      { name: "Turtle Island", uid: 1601 },
    ]
  },

  Alberta: {
    uid: 6,
    name: "Alberta",
    image: "🏔️",
    cities: [
      { name: "Calgary", uid: 601 },
      { name: "Edmonton", uid: 602 },
      { name: "Red Deer", uid: 603 },
      { name: "Lethbridge", uid: 604 },
      { name: "Fort McMurray", uid: 605 },
      { name: "Grande Prairie", uid: 606 },
      { name: "Medicine Hat", uid: 607 },
      { name: "Airdrie", uid: 608 },
      { name: "Spruce Grove", uid: 609 },
      { name: "Leduc", uid: 610 },
      { name: "...", uid: 9999 },
    ],
  },
  "British Columbia": {
    uid: 7,
    name: "British Columbia",
    image: "🏕️",
    cities: [
      { name: "Vancouver", uid: 701 },
      { name: "Victoria", uid: 702 },
      { name: "Kelowna", uid: 703 },
      { name: "Burnaby", uid: 704 },
      { name: "Surrey", uid: 705 },
      { name: "Richmond", uid: 706 },
      { name: "Abbotsford", uid: 707 },
      { name: "Coquitlam", uid: 708 },
      { name: "Langley", uid: 709 },
      { name: "Delta", uid: 710 },
      { name: "...", uid: 9999 },
    ],
  },
  Manitoba: {
    uid: 8,
    name: "Manitoba",
    image: "🌾",
    cities: [
      { name: "Winnipeg", uid: 801 },
      { name: "Brandon", uid: 802 },
      { name: "Steinbach", uid: 803 },
      { name: "Thompson", uid: 804 },
      { name: "Portage la Prairie", uid: 805 },
      { name: "Winkler", uid: 806 },
      { name: "Selkirk", uid: 807 },
      { name: "Dauphin", uid: 808 },
      { name: "Morden", uid: 809 },
      { name: "The Pas", uid: 810 },
      { name: "...", uid: 9999 },
    ],
  },
  "New Brunswick": {
    uid: 9,
    name: "New Brunswick",
    image: "🌾",
    cities: [
      { name: "Fredericton", uid: 901 },
      { name: "Moncton", uid: 902 },
      { name: "Saint John", uid: 903 },
      { name: "Edmundston", uid: 905 },
      { name: "Bathurst", uid: 906 },
      { name: "Campbellton", uid: 907 },
      { name: "Dieppe", uid: 908 },
      { name: "Miramichi", uid: 909 },
      { name: "Riverview", uid: 910 },
      { name: "...", uid: 9999 },
    ],
  },
  "Newfoundland and Labrador": {
    uid: 10,
    name: "Newfoundland and Labrador",
    image: "🎣",
    cities: [
      { name: "St. John's", uid: 1001 },
      { name: "Mount Pearl", uid: 1002 },
      { name: "Corner Brook", uid: 1003 },
      { name: "Conception Bay South", uid: 1004 },
      { name: "Grand Falls-Windsor", uid: 1005 },
      { name: "Paradise", uid: 1006 },
      { name: "Gander", uid: 1007 },
      { name: "Labrador City", uid: 1008 },
      { name: "Happy Valley-Goose Bay", uid: 1009 },
      { name: "Torbay", uid: 1010 },
      { name: "...", uid: 9999 },
    ],
  },
  "Nova Scotia": {
    uid: 11,
    name: "Nova Scotia",
    image: "⚓️",
    cities: [
      { name: "Halifax", uid: 1101 },
      { name: "Dartmouth", uid: 1102 },
      { name: "Sydney", uid: 1103 },
      { name: "Truro", uid: 1104 },
      { name: "New Glasgow", uid: 1105 },
      { name: "Glace Bay", uid: 1106 },
      { name: "Kentville", uid: 1107 },
      { name: "Amherst", uid: 1108 },
      { name: "Bridgewater", uid: 1109 },
      { name: "Yarmouth", uid: 1110 },
      { name: "...", uid: 9999 },

    ],
  },
  Ontario: {
    uid: 12,
    name: "Ontario",
    image: "🏛️",
    cities: [
      { name: "Ajax", uid: 1201 },
      { name: "Brampton", uid: 1202 },
      { name: "Burlington", uid: 1203 },
      { name: "Cambridge", uid: 1204 },
      { name: "Guelph", uid: 1205 },
      { name: "Hamilton", uid: 1206 },
      { name: "Kitchener", uid: 1207 },
      { name: "London", uid: 1208 },
      { name: "Markham", uid: 1209 },
      { name: "Milton", uid: 1210 },
      { name: "Mississauga", uid: 1211 },
      { name: "Niagara Falls", uid: 1212 },
      { name: "Oakville", uid: 1213 },
      { name: "Oshawa", uid: 1214 },
      { name: "Ottawa", uid: 1215 },
      { name: "St. Catharines", uid: 1216 },
      { name: "Toronto", uid: 1217 },
      { name: "Vaughan", uid: 1218 },
      { name: "Whitby", uid: 1219 },
      { name: "Windsor", uid: 1220 },
      { name: "...", uid: 9999 },
    ],
  },
  "Prince Edward Island": {
    uid: 13,
    name: "Prince Edward Island",
    image: "🗿",
    cities: [
      { name: "Charlottetown", uid: 1301 },
      { name: "Summerside", uid: 1302 },
      { name: "Stratford", uid: 1303 },
      { name: "Cornwall", uid: 1304 },
      { name: "Montague", uid: 1305 },
      { name: "Kensington", uid: 1306 },
      { name: "Souris", uid: 1307 },
      { name: "Alberton", uid: 1308 },
      { name: "Tignish", uid: 1309 },
      { name: "Georgetown", uid: 1310 },
      { name: "...", uid: 9999 },
    ],
  },
  Quebec: {
    uid: 14,
    name: "Quebec",
    image: "⛸️",
    cities: [
      { name: "Montreal", uid: 1401 },
      { name: "Quebec City", uid: 1402 },
      { name: "Laval", uid: 1403 },
      { name: "Gatineau", uid: 1404 },
      { name: "Longueuil", uid: 1405 },
      { name: "Sherbrooke", uid: 1406 },
      { name: "Saguenay", uid: 1407 },
      { name: "Levis", uid: 1408 },
      { name: "Trois-Rivieres", uid: 1409 },
      { name: "Terrebonne", uid: 1410 },
      { name: "...", uid: 9999 },
    ],
  },
  Saskatchewan: {
    uid: 15,
    name: "Saskatchewan",
    image: "🌾",
    cities: [
      { name: "Saskatoon", uid: 1501 },
      { name: "Regina", uid: 1502 },
      { name: "Prince Albert", uid: 1503 },
      { name: "Moose Jaw", uid: 1504 },
      { name: "Yorkton", uid: 1505 },
      { name: "Swift Current", uid: 1506 },
      { name: "North Battleford", uid: 1507 },
      { name: "Estevan", uid: 1508 },
      { name: "Weyburn", uid: 1509 },
      { name: "Lloydminster", uid: 1510 },
      { name: "...", uid: 9999 },
    ],
  },

  "Northwest Territories": {
    uid: 17,
    name: "Northwest Territories",
    image: "🗻",
    cities: [
      { name: "Yellowknife", uid: 1701 },
      { name: "Hay River", uid: 1702 },
      { name: "Inuvik", uid: 1703 },
      { name: "Fort Smith", uid: 1704 },
      { name: "Behchoko", uid: 1705 },
      { name: "...", uid: 9999 },
    ],
  },
  Nunavut: {
    uid: 18,
    name: "Nunavut",
    image: "❄️",
    cities: [
      { name: "Iqaluit", uid: 1801 },
      { name: "Rankin Inlet", uid: 1802 },
      { name: "Cambridge Bay", uid: 1803 },
      { name: "Pond Inlet", uid: 1804 },
      { name: "Arviat", uid: 1805 },
      { name: "...", uid: 9999 },
    ],
  },
  Yukon: {
    uid: 19,
    name: "Yukon",
    image: "🗻",
    cities: [
      { name: "Whitehorse", uid: 1901 },
      { name: "Dawson City", uid: 1902 },
      { name: "Watson Lake", uid: 1903 },
      { name: "Haines Junction", uid: 1904 },
      { name: "Carmacks", uid: 1905 },
      { name: "...", uid: 9999 },
    ],
  },


  "International": {
    uid: 55,
    name: "International",
    image: "🛫",
    cities: [
      { name: "United States", uid: 5501 },
      { name: "Mexico", uid: 5502 },
      { name: "South America", uid: 5503 },
      { name: "Europe", uid: 5504 },
      { name: "Middle East", uid: 5505 },
      { name: "Western Asia", uid: 5506 },
      { name: "East Asia", uid: 5507 },
      { name: "Southeast Asia", uid: 5508 },
      { name: "North Africa", uid: 5509 },
      { name: "East Africa", uid: 5510 },
      { name: "Southern Africa", uid: 5511 },
      { name: "West Africa", uid: 5512 },
      { name: "Oceania", uid: 5513 },
      { name: "Antarctica", uid: 5514 },]
  },
};

export const roles = [];

// const citiesPerProvince = Object.values(provinces).map(
//   (province) => province.cities
// );

// console.log(citiesPerProvince);

// const communitiesPerNation = Object.values(indigenousNations).map(
//   (nation) => nation.communities
// );

// console.log(communitiesPerNation);

export const Label = styled.span`
  font-weight: bold;
  border-radius: 3px;
  background: ${(props) => props.$color};
  color: white;
  padding: 8px 15px 7px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  font-family: "PT Sans", sans-serif;
  letter-spacing: 1px;
  margin: 20px;
  width: fit-content;
  animation: pulse 2.6s infinite ease-in-out;
  @keyframes pulse {
    0% {
      background-color: ${(props) => props.$color};
    }
    50% {
      background-color: ${(props) => props.theme.colors.black};
    }
    100% {
      background-color: ${(props) => props.$color};
    }
  }
`;
