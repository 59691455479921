import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Roots from "roots";
import reportWebVitals from "./reportWebVitals";

if (process.env.NODE_ENV !== "development") console.log = () => {};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Roots />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
