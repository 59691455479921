import styled, { css } from "styled-components";
import PanelFilters from "views/panels/panelfilters";
import PanelsWrapper from "views/panels/PanelsWrapper";
import { useState } from "react";
const ContentWrapper = styled.div`
  justify-content: flex-start;
  height: calc(100vh - 260px);
  width: calc(100vw - 65px);
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 15px;
  justify-content: flex-end;
  display: flex !important;
  background: white;
  justify-content: flex-start;
  background: #f9f9f9;
  @media screen and (max-width: 768px) {
    background: white;
    // background: red;
    // margin: 0px;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
  ${(props) =>
    props.$showFilters &&
    css`
      .Panels {
        width: 100%;
        height: auto;
        height: 100%;
        &.PanelFiltersWrapper {
          height: auto;
        }
        @media screen and (min-width: 768px) {
          height: 100%;
          &.PanelFiltersWrapper {
            height: 100%;
          }
        }
      }
    `}

  & > div {
    transition: width 0.2s, height 0.2s;
    height: 100%;
    @media screen and (min-width: 640px) {
      height: ${(props) => (props.$showFilters ? "calc(100%)" : "100%")};
    }
    @media screen and (min-width: 768px) {
      height: 100%;
      width: ${(props) => (props.$showFilters ? "calc(100%)" : "100%")};
    }
  }

  ${(props) =>
    props.$toolItem &&
    css`
      & > div {
        transition: width 0.2s, height 0.2s;
        height: 100%;
        @media screen and (min-width: 640px) {
          height: 100%;
        }
        @media screen and (min-width: 768px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 640px) {
        row-gap: 6px;
        background: black;
      }

      ${props.$toolItem?.width === "mobile" &&
      css`
        ${props.$showFilters &&
        css`
          & > div:first-child {
            width: 100%;
            @media screen and (min-width: 640px) {
              margin-bottom: 8px;
            }
            height: auto;
            @media screen and (min-width: 768px) {
              max-width: unset;
            }
          }
        `}
        @media screen and (min-width: 768px) {
          row-gap: 0px;
          flex-direction: column;
        }
      `}
    `}
`;

function AFContent(props) {
  const { $toolItem, $showFilters, pageName, content } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <ContentWrapper
      className="ContentWrapper"
      $showFilters={$showFilters}
      toolItem={$toolItem}
    >
      {$showFilters && (
        <PanelFilters
          className="PanelFilters"
          pageName={pageName}
          toolItem={$toolItem}
          $showFilters={$showFilters}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      )}
      <PanelsWrapper
        className="PanelsWrapper"
        toolItem={$toolItem}
        content={content}
      />
    </ContentWrapper>
  );
}

export default AFContent;
