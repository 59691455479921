import { useContext, useMemo, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import AppContext from "AppContext";
import styled, { css } from "styled-components";
import ToolDropdown from "views/tools/ToolDropdown";
import ToolTabs from "views/tools/ToolTabs";
import ToolContent from "views/tools/ToolContent";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import HelpCenterRoundedIcon from "@mui/icons-material/HelpCenterRounded";

const ToolsContainer = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 5500;
  display: block;
  // transform: translateX(-50%);
`;

const ToolsContent = styled.div`
  width: 100%;
  background: black;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: end;
  margin: auto;
  font-family: serif !important;
  * {
    box-sizing: border-box;
  }
  padding: 10px 10px;

  @media screen and (min-width: 640px) {
    padding: 21px 27px 23px 27px;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 22px;
    height: 90px;
    justify-content: center;
  }

  @media screen and (max-width: 640px) {
    box-shadow: 0px 0px 5px lightgray;
    width: 100%;
    margin: auto;
    transition: width 0.3s;
    ${(props) =>
      (props.$openToolsList || props.$openPopup) &&
      css`
        width: calc(100% - 20px);
        border-radius: 5px 5px 0px 0px;
      `}
    .MuiTabs-root {
      display: none;
    }
  }
`;

const HelpIcon = styled.div`
  width: 34px;
  height: 34px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: pt sans;
  font-weight: bold;
  color: white;
  svg {
    font-size: 1.75em;
  }
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

const ToggleIcon = styled.div`
  margin-left: 35px;
  margin-right: 15px;
  cursor: pointer;
  color: lightgrey;
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
  svg {
    font-size: 3em;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 45px;
  @media screen and (max-width: 640px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  @media screen and (min-width: 640px) {
    display: none;
  }
`;

const ProfileAvatar = styled(Avatar)`
  width: 45px !important;
  height: 45px !important;
  margin-left: 10px;
  margin-right: 20px;
  border
`;

export default function Tools() {
  const { platformUser } = useContext(AppContext);
  const userImage = platformUser?.user_metadata?.avatar_url;
  const tabs = useMemo(() => {
    const toolItems = {
      1: {
        text: "🔐 Admin Console",
        subtitle: "Manage your orgnization & team",
        width: "full-width",
        showFilters: true,
      },
      2: {
        text: "🗣️ Announcments",
        subtitle: "News and announcements",
        width: "tablet",
        showFilters: false,
      },
      // 3: {
      //   text: "🎯 Todos",
      //   width: "tablet",
      //   showFilters: true,
      // },
      3: {
        text: "💵 Funding",
        subtitle: "Explore funding opportunities",
        width: "tablet",
        showFilters: false,
      },
      // 5: {
      //   text: "💬 Messages",
      //   width: "tablet",
      //   showFilters: false,
      // },

      // 4: {
      //   text: "⚙️ Profile",
      //   subtitle: "Keep an up-to-date profile.",
      //   width: "tablet",
      //   showFilters: false,
      // },
    };

    const stateTabs = [];
    for (let index = 1; toolItems[index]; index++) {
      stateTabs.push({
        text: toolItems[index].text,
        subtitle: toolItems[index].subtitle,
        width: toolItems[index].width,
        showFilters: toolItems[index].showFilters,
      });
    }
    return stateTabs;
  }, []);

  const DefaultMobileTool = {
    text: "Profile Settings",
    width: "",
    showFilters: false,
  };

  const [selectedTool, setSelectedTool] = useState(null);
  const [$openPopup, set$openPopup] = useState(false);
  const [$openToolsList, set$openToolsList] = useState(false);

  const options = [
    ...tabs,
    {
      text: "Help",
      width: "mobile",
      showFilters: true,
    },
  ];

  const handleTogglePopup = () => {
    // set$openPopup(!$openPopup);
  };

  const handleClickMenu = () => {};

  const handleClickSelect = () => {
    if ($openPopup) {
      set$openPopup(false);
      set$openToolsList(false);
      setSelectedTool(DefaultMobileTool);
    } else {
      set$openToolsList(!$openToolsList);
    }
  };

  const handleOnClickTool = (tab) => {
    setSelectedTool(tab);
    setTimeout(() => {
      set$openToolsList(false);
    }, 500);

    // Determine if the popup is about to be closed
    const isPopupClosing = $openPopup && tab.text === selectedTool.text;

    if (!$openPopup) {
      setTimeout(() => {
        set$openPopup(true);
      }, 150);
    } else if (isPopupClosing) {
      // If the popup is closing, reset the selected tool
      setTimeout(() => {
        setSelectedTool(null);
      }, 150); // You might adjust this timeout to match your UX requirements
      set$openPopup(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => set$openToolsList(false)}>
      <ToolsContainer className="ToolsContainer">
        <ToolsContent
          className="ToolsContent"
          $openToolsList={$openToolsList}
          $openPopup={$openPopup}
        >
          <ToolTabs
            className="ToolTabs"
            tabs={tabs}
            tabValue={selectedTool?.text}
            handleOnClick={handleOnClickTool}
          />
          <MobileContainer className="MobileTools">
            <ToolDropdown
              className="ToolDropdown"
              options={options}
              handleOnChange={handleOnClickTool}
              value={selectedTool?.text}
              handleClick={handleClickSelect}
              open={$openToolsList}
              handleOnClose={() => {
                setSelectedTool(DefaultMobileTool);
                set$openToolsList(false);
              }}
            />
          </MobileContainer>
          <IconContainer className="IconContainer">
            <HelpIcon
              className="HelpIcon"
              onClick={() => {
                handleOnClickTool({
                  text: "Help",
                  width: "mobile",
                  showFilters: true,
                });
              }}
            >
              <HelpCenterRoundedIcon fontSize="large" />
            </HelpIcon>
            <ToggleIcon className="ToggleIcon" onClick={handleTogglePopup}>
              {$openPopup ? (
                <ArrowDropUpRoundedIcon fontSize="large" />
              ) : (
                <ArrowDropDownRoundedIcon fontSize="large" />
              )}
            </ToggleIcon>
            <ProfileAvatar alt="" src={userImage} onClick={handleClickMenu} />
          </IconContainer>
          <ToolContent
            toolItem={selectedTool}
            open={$openPopup}
            handleOnClose={() => {
              setSelectedTool(DefaultMobileTool);
              set$openPopup(false);
            }}
          />
        </ToolsContent>
      </ToolsContainer>
    </ClickAwayListener>
  );
}
