import AutoGraphTwoToneIcon from "@mui/icons-material/AutoGraphTwoTone";
import ContactlessTwoToneIcon from "@mui/icons-material/ContactlessTwoTone";
import FeedTwoToneIcon from "@mui/icons-material/FeedTwoTone";
import NotificationsTwoToneIcon from "@mui/icons-material/NotificationsTwoTone";
import WidgetsTwoToneIcon from "@mui/icons-material/WidgetsTwoTone";
import Profile from "pages/login/profile";
import styled from "styled-components";
import SectionWrapper from "utilities/SectionWrapper";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import ProfileForm from "views/onboarding/profile";
import ProfileEvents from "views/onboarding/profileevents";
import { ProfileProgress } from "views/onboarding/profileprogress";

const Dash = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  background: white;
  // background: #f9f9f9;
  width: calc(100vw - 65px);
  height: calc(100vh - 90px);
  @media (max-width: 768px) {
    flex-direction: column;
    // width: calc(100vw - 80px);
  }
`;

const LeftSide = styled.div`
  display: flex;
  background: #111111;
  background: black;
  box-sizing: border-box;
  flex-direction: column;
  transition: padding, 0.5s;
  padding: 20px 20px 15px;
  gap: 15px;
  z-index: 2;
  transition: width: 0.9s;
  min-width: 315px;
  width: 315px;
  height: calc(100vh - 90px);
  @media screen and (max-width: 768px) {
    width: 100%;
    .ProfileCard {
      max-width: 100%;
    }
  }
`;

const LeftSideContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: hidden;
  border-radius: 3px;
  height: fill-available;
  @media screen and (max-height: 768px) {
    // overflow-y: scroll;
    // margin-right: -5px;
  }
  @media screen and (max-width: 768px) {
    // display: none;
  }
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: calc(100vh - 90px);
  width: calc(100vw - 380px);
  padding: 25px 15px 15px;
  background: #f7f7f7;
  @media screen and (max-width: 768px) {
    height: calc(100vh - 200px);
    width: calc(100vw - 65px);
  }
  @media screen and (max-width: 640px) {
    // height: calc(100vh - 315px);
  }
`;

const WallMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  // align-self: center;
  box-sizing: border-box;
  gap: 10px;
  align-items: center;
  padding: 20px 0px 20px 0px;
  margin-left: 40px;
  @media screen and (max-width: 1165px) {
    align-self: initial;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-bottom: 20px;
    padding-top: 15px;
    margin-left: 20px;
  }
  @media screen and (max-width: 640px) {
    // margin-bottom: 0px;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
  height: 30px;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  background: #aecad23d;
  gap: 6px;
  transition: all, 0.3s;
  &:hover,
  .active {
    background: black;
    color: white;
    cursor: pointer;
  }
`;

const WallContent = styled.div`
  height: calc(100% - 80px);
  box-shadow: 0px 0px 5px 1px #f1f1f1;
  // box-shadow: 0px 0px 42px 3px rgba(150, 150, 150, 0.2);
  overflow: auto;
  border-radius: 5px;
  background: white;
  border: 1px solid #f8f8f8;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  box-sizing: border-box;
  gap: 15px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const WallItem = styled(WallContent)`
  display: flex;
  padding: 25px;
  height: 100px;
  font-size: 21px;
  font-weight: 900;
  color: #111111;
  background: #f1f1f122;
  flex: 1;
  &:hover {
    background: black;
    color: white;
    transition: all, 0.3s;
  }
`;

const Announcements = styled(WallItem)`
  flex: 1;
`;
const News = styled(WallItem)`
  flex: 3;
`;
const Resources = styled(WallItem)``;
const Index = styled(WallItem)`
  flex: 2.5;
  display: none;
`;
const Live = styled(WallItem)`
  flex: 2.5;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  // background: #f1f1f1;
  height: 100%;
`;

const Left = styled(Columns)`
  flex: 1;
`;
const Right = styled(Columns)`
  flex: 1;
`;

const DashboardContent = styled.div`
  height: calc(100%);
  &.hide {
    display: none;
  }
`;

export default function Dashboard() {
  return (
    <SectionWrapper>
      <Dash className="SuperApp">
        <LeftSide className="LeftSide">
          {" "}
          <Profile />
          <LeftSideContent className="LeftSideContent">
            <ProfileProgress />
            <ProfileEvents />
          </LeftSideContent>
        </LeftSide>
        <RightSide className="RightSide">
          <DashboardContent className="hide">
            <WallContent style={{ paddingBottom: "0px" }}>
              <ProfileForm />
            </WallContent>
          </DashboardContent>
          <DashboardContent>
            <WallMenu>
              <MenuItem className="MenuItem">
                <ContactlessTwoToneIcon fontSize="small" />
                All
              </MenuItem>
              <MenuItem className="MenuItem">
                <ModeStandbyIcon fontSize="small" />
                Live!
              </MenuItem>
              <MenuItem className="MenuItem">
                <NotificationsTwoToneIcon fontSize="small" />
                Announcements
              </MenuItem>
              <MenuItem className="MenuItem">
                <FeedTwoToneIcon fontSize="small" />
                News
              </MenuItem>
              <MenuItem className="MenuItem">
                <WidgetsTwoToneIcon fontSize="small" />
                Resources
              </MenuItem>
              <MenuItem className="MenuItem">
                <AutoGraphTwoToneIcon fontSize="small" />
                Index
              </MenuItem>
            </WallMenu>
            <WallContent>
              <Left>
                <Announcements>Announcements</Announcements>
                <News>News</News>
              </Left>
              <Right>
                <Live>Live</Live>
                <Index>Index</Index>
                <Resources>Resources</Resources>
              </Right>
            </WallContent>
          </DashboardContent>
        </RightSide>
      </Dash>
    </SectionWrapper>
  );
}
