import React from "react";
import styled from "styled-components";
import { useContext } from "react";
import AppContext from "AppContext";

const LeftTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: inherit;
  background: url(${(props) => props.$backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: centre;
  padding: 20px 20px 20px 20px;
  box-sizing: border-box;
  width: 100%;
  flex: 1;
  gap: 20px;
  z-index: 1; 
  @media screen and (max-width: 768px) {
    border-radius: 5px 5px 0px 0px;
    padding: 20px 20px 20px 25px;
  }
`;

const LeftTitle = styled.div`
  color: white;
  font-size: 42px;
  font-family: pt sans;
  font-weight: 600;
  text-transform: none;
  max-width: 85%;
  line-height: 55px;
  // background: red;
  span {
    background-color: ${(props) => `${props.$color}99`};
    border-radius: 5px;
    padding: 0px 5px 0px 0px;
    // display: inline-block;
  }
  @media screen and (max-width: 1200px) {
  }
  @media screen and (max-width: 1024px) {
    font-size: 35px;
    line-height: 42px;
    max-width: 100%;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const LeftTitleImage = styled.div`
  background: url("${(props) => props.src}");
  background-size: contain;
  height: 100px;
  background-repeat: no-repeat;
  @media screen and (max-width: 768px) {
    height: 40px;
  }
`;

const LeftComponentContent = styled.div`
 position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  z-index: 2;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

export default function HomeTitle() {
  const { selectedNavItem, platformUser } = useContext(AppContext);

  return (
    <LeftComponentContent>
      {/* {selectedNavItem?.backgroundVideo &&
        <Video type="video/mp4" src={selectedNavItem?.backgroundVideo} autoPlay muted playsInline />
      } */}
      <LeftTitleContainer $backgroundImage={selectedNavItem?.backgroundImage}>
        {selectedNavItem?.image && <LeftTitleImage src={selectedNavItem.image} />}
        <LeftTitle $color={selectedNavItem?.secondarycolor}>
          {!platformUser ? (
            <>
              <span>{selectedNavItem?.header || selectedNavItem?.title}</span>
            </>
          ) : (
            <>
              <span>{selectedNavItem?.title}</span>
            </>
          )}
        </LeftTitle>
      </LeftTitleContainer>
    </LeftComponentContent>
  );
}
