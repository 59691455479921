import { useContext, useMemo, useEffect, useState } from "react";
import AppContext from "AppContext";
// import { motion } from "framer-motion";
import styled, { css } from "styled-components";
import PanelCard from "./panelcard";

const PanelFiltersWrapper = styled.div`
  background: #f1f1f1;
  padding: 0px;
  padding-top: 15px;
  box-sizing: border-box;
  max-width: 355px;
  height: calc(100vh - 90px);
  width: 100%;
  transition: width, 0.5s;
  height: fit-content;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    padding-bottom: 10px;
    background: black;
    z-index: 3;
    height: auto;
    height: fit-content !important;
  }
  ${(props) =>
    props.$toolpanelCard &&
    css`
      @media screen and (max-width: 640px) {
        box-shadow: unset;
      }
    `}
  &.collapsed {
    width: 110px !important;
    transition: width, 0.2s;
    overflow: hidden;
  }
`;

const PanelFiltersContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  padding: 0px 15px 20px;
  margin: 0px;
  box-sizing: border-box;
  gap: 5px;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: thin;
  ::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 20px 0px 0px 15px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: black;
  }
  @media screen and (max-width: 768px) {
    height: 100px;
    flex-direction: row;
    padding-bottom: 10px;
    ::-webkit-scrollbar-track {
      background-color: transparent;
      margin-top: 20px;
      margin-left: 15px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: white;
      height: 5px;
    }
  }

  ${(props) =>
    props.$toolpanelCard &&
    css`
      padding-bottom: 30px;
      display: flex;
      height: 96%;
      max-height: 98% !important;
      @media screen and (min-width: 768px) {
        height: 96% !important;
        padding-bottom: 30px;
      }
      ${props.$toolpanelCard.width === "mobile" &&
      css`
        @media screen and (min-width: 768px) {
          max-width: calc(100% - 30px);
          flex-direction: row;
          overflow-x: auto;
          overflow-y: hidden;
          padding: 15px 0px 7.5px 0px;
          margin: auto;
          height: 100%;
        }
      `}
    `}
`;

const PanelFiltersCard = styled.div`
  justify-content: space-between;
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid transparent;
  &.selected {
    border: 2px solid ${(props) => props.theme.colors.black};
    border-radius: 3px;
  }
`;

function PanelFilters(props) {
  const {
    platformData,
    platformActiveTab,
    platformActiveCard,
    setPlatformActiveCard,
  } = useContext(AppContext);

  const { toolpanelCard, pageName } = props;

  const handleSelectedpanelCard = (panelCard) => {
    setPlatformActiveCard(panelCard);
  };

  const panelFiltersData = useMemo(() => {
    const page = platformData?.pages?.find((sec) => sec.title === pageName);

    if (page) {
      const activeSection = page.sections.find(
        (section) => section.title === platformActiveTab
      );
      return activeSection ? activeSection.content : [];
    }

    return [];
  }, [platformData, pageName, platformActiveTab]);

  useEffect(() => {
    console.log(platformActiveCard);
  }, [platformActiveCard]);

  console.log("panelFiltersData", panelFiltersData);

  return Object.keys(panelFiltersData).length !== 0 ? (
    <PanelFiltersWrapper
      className={`PanelFiltersWrapper, PanelFilters ${
        props.isCollapsed ? "collapsed" : ""
      }`}
      toolpanelCard={toolpanelCard}
    >
      {/* <button onClick={() => props.setIsCollapsed(!props.isCollapsed)}>
        {props.isCollapsed ? "Expand" : "Collapse"}
      </button>{" "}
      <br />
      <br /> */}
      <PanelFiltersContent
        className="PanelFiltersContent"
        toolpanelCard={toolpanelCard}
      >
        {panelFiltersData.map((panelCard, i) => (
          <PanelFiltersCard
            className={`PanelFiltersCard ${
              panelCard === platformActiveCard ? "selected" : ""
            }`}
            onClick={() => {
              handleSelectedpanelCard(panelCard);
            }}
          >
            <PanelCard
              item={panelCard}
              toolpanelCard={toolpanelCard}
              fullWidth
            />
          </PanelFiltersCard>
        ))}
      </PanelFiltersContent>
    </PanelFiltersWrapper>
  ) : null;
}

export default PanelFilters;
