import { useContext } from "react";
import { navItems } from "./navutils.js";
import styled from "styled-components";
import AppContext from "AppContext";

const NavMenu = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: left;
  align-content: center;
  align-items: flex-start;
  align-self: center;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 10px;
  padding-top: 10px;
  z-index: 100;
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
  }
  @media screen and (max-width: 1024px) {
    padding-right: 15px;
  }
`;

const NavItem = styled.div`
  gap: 5px;
  position: relative;
  width: 100%;
  display: flex;
import AppContext from "AppContext";
import styled from "styled-components";
  height: 100%;
  flex-direction: column;
  &:hover {
    cursor: pointer;
    .NavItemName {
      background-color: ${(props) => props.color1};
      color: black;
      transition: all 0.3s;
    }
    .NavItemIcon {
      color: ${(props) => props.color1};
      transition: all 0.3s;
    }
  }
  &.active {
    .NavItemName {
      background-color: ${(props) => props.color1};
      color: black;
    }
    .NavItemIcon {
      color: ${(props) => props.color1};
    }
  }
`;

const NavItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 5px;
  gap: 10px;
  height: fit-content;
  padding: 5px 18px;
  width: initial;
  color: #f9f9f9;
  // background: #212121;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
`;

const NavItemName = styled.span`
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  font-family: PT Sans;
  letter-spacing: 1.5px;
  text-align: center;
  justify-content: center;
  display: flex;
  color: white;
  width: 100%;
  border-radius: 2px;
  padding: 3px 8px;
  box-sizing: border-box;
`;

export default function Nav() {
  const { setPlatformPageName, platformPageName } = useContext(AppContext);
  return (
    <NavMenu>
      {navItems.map((item, index) => (
        <NavItem
          key={index}
          className={platformPageName === item.pageName ? "active" : ""}
          onClick={() => setPlatformPageName(item.pageName)}
          color1={item.color1}
          color2={item.color2}
        >
          <NavItemIcon
            className={
              platformPageName === item.pageName
                ? "NavItemIcon active"
                : "NavItemIcon"
            }
            color1={item.color1}
            color2={item.color2}
          >
            {item.icon}
          </NavItemIcon>
          <NavItemName
            className={
              platformPageName === item.pageName
                ? "NavItemName active"
                : "NavItemName"
            }
            color1={item.color1}
            color2={item.color2}
          >
            {item.name}
          </NavItemName>
        </NavItem>
      ))}
    </NavMenu>
  );
}
