import styled from "styled-components";
import Select from "react-select";
import { useContext } from "react";
import AppContext from "AppContext";

const CardContainer = styled.div`
  background-color: white;
  box-shadow: 0px 0px 13px -4px lightgrey;
  border: solid 1px #f1f1f1; // New from CardContainerPlatform
  border-radius: 3px;
  text-align: left;
  padding: 15px 16px 17px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  &.selected {
    border-radius: 4px;
    border: 1px solid black;
  }
`;
const CardContent = styled.div`
  display: flex;
  flex-gap: 10px;
  flex-direction: column;
  gap: 5px;
`;

const Title = styled.div`
  line-height: 21px;
  letter-spacing: 0.5px;
  color: black;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: white;
    min-width: 175px;
  }
`;

const Image = styled.div`
  min-width: 100px;
  display: flex;
  height: 100%;
  background-image: url(${(props) => props.$image});
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  background-size: cover;
  border-radius: 5px;
`;

const Icon = styled(Image)`
  min-width: 50px;
  max-width: 50px;
  background-size: contain;
  justify-self: flex-start;
  align-self: center;
  height: 50px;
`;

const Tag = styled.span`
  background: gray;
  border-radius: 4px;
  padding: 3px 6px;
  font-family: "Roboto";
  max-width: max-content;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.15008px;
  color: white;
`;

const Description = styled.p`
  margin: 0px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 18px;
  font-size: 13px;
  letter-spacing: 0.13132px;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Set the maximum number of lines you want to display */
  color: black;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StatusContainer = styled.div`
  width: 100%;
  // Add any additional styles you need
`;

const Status = styled(Select)`
  border-radius: 1px;
  font-family: "Roboto";
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.15008px;
  color: black;
  width: fit-content;
  margin-left: -5px;
  width: 100%;
`;

const options = [
  { value: "Ready to Start", label: "🎯 Ready to Start" },
  { value: "In Progress", label: "🏗️ In Progress" },
  { value: "Request Review", label: "🙋🏽‍♀️ Request Review" },
  { value: "Complete", label: "✅ Complete" },
  { value: "On Hold", label: "🚦 On Hold" },
];

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

// const Tag2 = styled(Tag)`
//   background: #e3e3e355;
//   color: black;
//   text-transform: uppercase;
//   font-size: 12px;
//   font-weight: 500;
//   padding: 10px;
//   max-width: none;
// `;

export default function PanelCard(props) {
  const { platformActiveCard } = useContext(AppContext);

  const { toolItem, item, selected } = props;

  console.log(item, "item");
  console.log(item.title, "item.title");

  const defaultOption = options.find((option) => option.value === item.Status);

  const handleStatusClick = (event) => {
    event.stopPropagation();
  };

  return (
    <CardContainer
      className={`CardContainer ${selected ? "selected" : ""}`}
      $fullwidth={props.fullwidth}
      toolItem={toolItem}
    >
      <MetaContainer>
        {item.meta.Icon && (
          <Icon
            className="CardIcon"
            src={item.meta.Icon}
            $image={item.meta.Icon}
            alt={item.title}
          />
        )}
        {item.meta.Image && (
          <Image
            className="CardImage"
            src={item.meta.Image}
            $image={item.meta.Image}
            alt={item.title}
          />
        )}
        {/* <Tag2 className="CardTag" color={item.color}>
          {item.tag}
          {randomPercentage}
        </Tag2> */}
      </MetaContainer>
      <CardContent className="CardContent">
        <Title className="CardTitle">{item.title}</Title>
        {item.tag && (
          <Tag className="CardTag" color={item.color}>
            {item.tag}
          </Tag>
        )}
        <Description className="CardDescription">
          {item.description}
        </Description>
        {item.Status && (
          <StatusContainer onClick={handleStatusClick}>
            <Status
              placeholder="Select Status"
              defaultValue={defaultOption}
              options={options}
            ></Status>
          </StatusContainer>
        )}
      </CardContent>
    </CardContainer>
  );
}
