import styled, { css } from "styled-components";
import Panel2 from "views/panels/panel2";
import Panel3 from "views/panels/panel3";
import PanelActions from "views/panels/panelactions";
import { useState } from "react";

const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  box-sizing: border-box;
  height: 100%;
  ${(props) => props.$toolItem && "padding: 0"};
  width: 100%;
`;

const PanelWrapperContent = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 90px);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;

  @media screen and (min-width: 640px) {
    padding-bottom: 0px;
  }
  @media screen and (max-width: 640px) {
    // background: white;
    row-gap: 6px;
    width: auto;
    border-radius: ${(props) =>
      props.$showFilters || props.$toolItem ? "5px" : "0px 0px 5px 5px"};
    // height: calc(100%);
  }
  ${(props) =>
    props.$toolItem &&
    css`
      margin: 0px;
      @media screen and (min-width: 640px) {
        // padding-left: ${props.$showFilters ? "15px" : "0px"};
        // padding-right: ${props.$showFilters ? "5px" : "0px"};
      }
      @media screen and (max-width: 640px) {
        row-gap: 13px;
      }
      padding-bottom: 0px;
    `}
`;

function PanelsWrapper(props) {
  const { toolItem, content } = props;
  const [panel2, setPanel2] = useState("");

  return (
    <PanelWrapper className="Panels" toolItem={toolItem}>
      <PanelWrapperContent className="ContainerContent" toolItem={toolItem}>
        <Panel3
          toolItem={toolItem}
          content={content}
          panel2={panel2} // Pass the state as a prop to Panel3
          setPanel2={setPanel2} // Pass the setter function as a prop to Panel3
        />
        <Panel2 panel2={panel2} />
      </PanelWrapperContent>
      {!toolItem && <PanelActions />}
    </PanelWrapper>
  );
}

export default PanelsWrapper;
