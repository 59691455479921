import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Groups2Icon from "@mui/icons-material/Groups2";
import FlagIcon from "@mui/icons-material/Flag";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import styled from "styled-components";

const ProfileTabList = styled(Tabs)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
  height: 100%;
`;

const ProfileTab = styled(Tab)`
  background: #f9f9f9;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  color: #1d1e2f;
  gap: 6px;
  &:hover {
    background: #1d1e2f;
    color: white;
  }
`;

export default function ProfileTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ProfileTabList
      value={value}
      onChange={handleChange}
      aria-label="icon position tabs example"
    >
      <ProfileTab
        icon={<AccountBoxIcon />}
        iconPosition="start"
        label="Profile"
      />
      <ProfileTab icon={<Groups2Icon />} iconPosition="start" label="Team" />
      <ProfileTab
        icon={<FolderCopyIcon />}
        iconPosition="start"
        label="Company"
      />
      <ProfileTab icon={<FlagIcon />} iconPosition="start" label="Goals" />
    </ProfileTabList>
  );
}
