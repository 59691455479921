import styled, { css } from "styled-components";
import PanelFilters from "views/panels/panelfilters";
import PanelsContainer from "views/panels/PanelsWrapper";

const PanelsWrapper = styled.div`
  justify-content: flex-start;
  height: calc(100vh - 260px);
  width: calc(100vw - 65px);
  position: relative;
  flex-direction: column;
  flex-wrap: nowrap;
  row-gap: 15px;
  justify-content: flex-end;
  display: flex !important;
  background: white;
  justify-content: flex-start;
  background: #f9f9f9;
  @media screen and (max-width: 768px) {
    background: white;
    // background: red;
    // margin: 0px;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    // background: red;
  }
  ${(props) =>
    props.$showFilters &&
    css`
      & > div:first-child {
        width: 100%;
        height: auto;
        @media screen and (min-width: 768px) {
          height: 100%;
        }
      }
    `}

  & > div {
    transition: width 0.2s, height 0.2s;
    height: 100%;
    @media screen and (min-width: 640px) {
      height: ${(props) => (props.$showFilters ? "calc(100%)" : "100%")};
    }
    @media screen and (min-width: 768px) {
      height: 100%;
      width: ${(props) => (props.$showFilters ? "calc(100%)" : "100%")};
    }
  }

  ${(props) =>
    props.$toolItem &&
    css`
      & > div {
        transition: width 0.2s, height 0.2s;
        height: 100%;
        @media screen and (min-width: 640px) {
          height: 100%;
        }
        @media screen and (min-width: 768px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 640px) {
        row-gap: 6px;
        background: black;
      }

      ${props.$toolItem?.width === "mobile" &&
      css`
        ${props.$showFilters &&
        css`
          & > div:first-child {
            width: 100%;
            @media screen and (min-width: 640px) {
              margin-bottom: 8px;
            }
            height: auto;
            @media screen and (min-width: 768px) {
              max-width: unset;
            }
          }
        `}
        @media screen and (min-width: 768px) {
          row-gap: 0px;
          flex-direction: column;
        }
      `}
    `}
`;

function Panels(props) {
  const { $toolItem, $showFilters, pageName, content } = props;
  return (
    <PanelsWrapper $showFilters={$showFilters} toolItem={$toolItem}>
      {$showFilters && (
        <PanelFilters pageName={pageName} toolItem={$toolItem} />
      )}
      <PanelsContainer
        toolItem={$toolItem}
        $showFilters={$showFilters}
        content={content}
      />
    </PanelsWrapper>
  );
}

export default Panels;
