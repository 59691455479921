import { Tabs as MuiTabs, Tab } from "@mui/material";
import styled from "styled-components";

const Tabs = styled(MuiTabs)`
  &.MuiTabs-root {
    min-height: 35px;
    font-family: pt sans;
  }
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTab-root {
    color: white;
    font-weight: 700;
    font-family: pt sans;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 1px;
    text-transform: unset;
    border-radius: 3px;
    padding: 15px;
    height: 35px;
    min-height: 35px;
    margin: 0px 15px;
    // background-color: #282d3899;
    &:hover {
      background-color: #282d38;
      transition: color, 0.2s;
    }
    &.Mui-selected {
      background: white;
      background: ${(props) => props.theme.primaryColor};
      color: black;
    }
  }
`;

function ToolTabs(props) {
  const { tabs, tabValue, handleOnClick } = props;

  return (
    <Tabs variant="scrollable" scrollButtons={false} value={tabValue}>
      {tabs.map((tab, idx) => (
        <Tab
          className="Tab"
          id={`tab${idx + 1}`}
          key={idx}
          disableRipple
          disableFocusRipple
          value={tab.value}
          label={tab.text}
          onClick={() => handleOnClick(tab)}
        />
      ))}
    </Tabs>
  );
}

export default ToolTabs;
